import React, { useEffect, useRef, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import translationKeys from 'translations/translationKeys';
import parsePhoneNumber from 'libphonenumber-js';
import { useDispatch, useSelector } from 'react-redux';
import {
  registerCustomer, validateCustomerActivation,
  resendCustomerActivationCode,
  resetAccountCreation,
  resetAccountValidation,
} from 'redux/customer-account-management/customerAccountManagementSlice';
import { setDashboard } from 'redux/nav-header/navHeader';
import accountCreationIcon from '../../../../assets/images/mathys/svg/account-creation.svg';

const AccountManagement = () => {
  const initialTimeOut = 60;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { agentId } = useSelector((store) => store.login);
  const {
    accountCreation, accountValidation, resendActivationCode,
  } = useSelector((store) => store.customerAccount);
  const { data } = useSelector((store) => store.systemConfigs);
  const [formState, setFormState] = useState('creation');
  const [invalidNumber, setInvalidNumber] = useState(true);
  const [otp, setOtp] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState(data?.DEFAULT_COUNTRY_CODE ?? '+243');
  const [phoneLength, setPhoneLength] = useState(Number(data?.PHONE_NUMBER_LENGTH ?? '9'));
  const randomPhoneNumber = '1'.repeat(phoneLength);
  const validator = parsePhoneNumber(data.DEFAULT_COUNTRY_CODE + randomPhoneNumber);
  const [country, setCountry] = useState(validator?.country ?? 'DC');
  const [phoneNumberValidator, setPhoneNumberValidator] = useState(null);
  const [timeOut, setTimeOut] = useState(initialTimeOut);
  const timeOutIntervalRef = useRef(null);

  const {
    msg4, msg51, msg55, msg182, msg183, msg184,
    msg185, msg186, msg187, msg189, msg190, msg191, msg192,
  } = translationKeys;

  const getValidPhoneNumber = (event) => {
    const number = event.target.value;
    setPhoneNumber(number);
    if (number.length === phoneLength) {
      const validator = parsePhoneNumber(number, country);
      if (validator?.isValid()) {
        setInvalidNumber(false);
        setPhoneNumberValidator(validator);
      } else {
        setInvalidNumber(true);
        setPhoneNumberValidator(null);
      }
    } else {
      setInvalidNumber(true);
      setPhoneNumberValidator(null);
    }
  };

  const getOtp = (event) => {
    const otpValue = event.target.value;
    setOtp(otpValue);
  };

  const validOtp = () => otp.length === 4;

  const formattedPhoneNumber = () => phoneNumberValidator?.formatInternational() ?? phoneNumber;

  const getTitle = () => {
    switch (formState) {
      case 'validation':
        return t(msg186);
      case 'activation':
        return t(msg184);
      default:
        return t(msg183);
    }
  };

  const switchCreation = () => {
    setFormState(formState === 'creation' ? 'activation' : 'creation');

    const title = formState === 'creation' ? msg184 : msg182;

    dispatch(setDashboard({ name: title, redirect: 'home' }));
  };

  const switchButtonTitle = () => {
    if (formState === 'creation') {
      return t(msg184);
    }

    if (formState === 'activation') {
      return t(msg182);
    }

    return null;
  };

  const resendCode = () => {
    dispatch(resendCustomerActivationCode(countryCode + phoneNumber));
  };

  const resetForm = () => {
    setFormState('creation');
    document.getElementById('account-form').reset();
    setPhoneNumber('');
    setInvalidNumber(true);
    setPhoneNumberValidator(null);
  };

  const cancelValidation = () => {
    dispatch(resetAccountCreation());
    dispatch(resetAccountValidation());
    resetForm();
  };

  const customerManagement = (e) => {
    e.preventDefault();
    switch (formState) {
      case 'validation':
        dispatch(validateCustomerActivation({
          agentId: accountCreation.retailCustomerId,
          activationCode: otp,
        }));
        break;
      case 'activation':
        resendCode();
        break;
      default:
        dispatch(registerCustomer({
          agentId,
          phone: phoneNumber,
        }));
        break;
    }
  };

  useEffect(() => {
    if (formState === 'validation') {
      if (timeOut > 0) {
        timeOutIntervalRef.current = setInterval(() => {
          setTimeOut((prevTimeOut) => prevTimeOut - 1);
        }, 1000);
      }

      if (timeOut === 0) {
        clearInterval(timeOutIntervalRef.current);
      }
    }

    return () => {
      clearInterval(timeOutIntervalRef.current);
    };
  }, [timeOut, formState]);

  useEffect(() => {
    if (accountCreation.success || (resendActivationCode.success && formState === 'activation')) {
      setFormState('validation');
    }

    if (accountValidation.success) {
      resetForm();
    }
  }, [accountCreation, accountValidation, resendActivationCode, formState]);

  useEffect(() => {
    if (resendActivationCode.success) {
      setTimeOut(initialTimeOut);
    }
  }, [resendActivationCode, dispatch]);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setCountryCode(data.DEFAULT_COUNTRY_CODE);
      setPhoneLength(Number(data.PHONE_NUMBER_LENGTH));
      const randomPhoneNumber = '1'.repeat(phoneLength);
      const { country } = parsePhoneNumber(data.DEFAULT_COUNTRY_CODE + randomPhoneNumber);
      setCountry(country);
    }
  }, [data]);

  return (
    <div className="details history">
      <div className="header">
        <img src={accountCreationIcon} alt="" />
        <span>{getTitle()}</span>
      </div>
      <div className="content">
        <form id="account-form" className="account-form" onSubmit={customerManagement}>
          <div className="content-grid">
            {formState !== 'validation' && (
            <>
              <div className="input-wrap">
                <label htmlFor="phoneNumber" className="form-label ">
                  {t(msg185)}
                </label>
                <div className="tel-input-group">
                  <div
                    className="country"
                  >
                    <span>{countryCode}</span>
                  </div>
                  <div className="input-field">
                    <input
                      placeholder="ex: 892651248"
                      id="phoneNumber"
                      type="tel"
                      value={phoneNumber}
                      onChange={getValidPhoneNumber}
                      onKeyDown={(e) => !(/^\d+$/.test(e.key) || e.key === 'Backspace' || e.key === 'Delete') && e.preventDefault()}
                      className={`form-control${(invalidNumber && phoneNumber.length > 0) ? ' error' : ''}`}
                    />
                    {invalidNumber && phoneNumber.length > 0 && (
                    <div className="error-block">
                      <span>{t(msg187)}</span>
                    </div>
                    )}
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-secondary"
                disabled={invalidNumber}
              >
                <span>{t(msg55)}</span>
              </button>
            </>
            )}
            {formState === 'validation' && (
            <>
              <div className="activation-info">
                <p>{t(msg189)}</p>
                <span>{formattedPhoneNumber()}</span>
              </div>
              <div className="resend-block">
                <div className="info">
                  <p>{t(msg190)}</p>
                  {timeOut > 0 && (<p><Trans i18nKey="resend-code" values={{ seconds: timeOut.toString() }} components={{ 1: <span /> }} /></p>)}
                </div>
                <button type="button" className="btn btn-resend" disabled={timeOut !== 0} onClick={resendCode}>{msg191}</button>
              </div>
              <div className="input-wrap">
                <label htmlFor="otpCode" className="form-label ">
                  {t(msg192)}
                </label>
                <input
                  type="text"
                  className="form-control verify-input"
                  id="otpCode"
                  required
                  placeholder={t(msg4)}
                  onChange={getOtp}
                />
              </div>
              <div className="actions no-padding">
                <button
                  type="button"
                  className="btn btn-cancel"
                  onClick={cancelValidation}
                >
                  <span>{t(msg51)}</span>
                </button>
                <button type="submit" className="btn btn-secondary" disabled={!validOtp()}>
                  <span>{t(msg55)}</span>
                </button>
              </div>
            </>
            )}
          </div>
          {formState !== 'validation' && (
          <button type="button" className="btn btn-switch" onClick={switchCreation}>
            <span>{switchButtonTitle()}</span>
          </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default AccountManagement;
