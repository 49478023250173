import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { authParams, getBase64Hash, parseParams } from 'components/helpers/base64AuthParams';
import getLanguage from 'components/helpers/getLanguage';
import translationKeys from 'translations/translationKeys';
import tokenText from 'components/helpers/tokenText';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});
const { msg29 } = translationKeys;
export const placeBet = createAsyncThunk(
  'bets/placeBet',
  async (objects, thunkAPI) => {
    const token = JSON.parse(sessionStorage.getItem('token'));
    const requests = objects.map((obj) => {
      const body = { ...obj, lang: getLanguage() };
      const requestParams = parseParams(body);
      const api = `/bet?${requestParams}`;
      const url = `${apiBaseUrl}/${apiVersion2}${api}`;
      const signatureParam = `/bet${requestParams}`;
      const headers = {
        'Content-Type': 'application/json; charset=UTF-8',
        'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}${JSON.stringify({})}`)}`,
        Authorization: token ? `Bearer ${token}` : '',
      };
      return client.post(url, {}, { headers });
    });
    try {
      const responses = await Promise.all(requests);
      return responses.map((response) => response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue({
        message: error.response.data.message || 'An error occurred',
        erc: error.response.data.erc,
      });
    }
  },
);

const initialState = {
  placeBets: [],
  isPlaceBetLoading: false,
  success: false,
  placedBetTicketNumber: '',
  msg: '',
  error: '',
  failedPlacedBet: false,
  checkedFailedPlacedBet: false,
};

const placeBetsSlice = createSlice({
  name: 'place-bet',
  initialState,
  reducers: {
    resetSuccess: (state) => ({
      ...state,
      ...initialState,
    }),
    checkFailedBet: (state) => {
      const failedBet = localStorage.getItem('placedBet');
      const failedPlacedBet = failedBet !== null;

      return { ...state, failedPlacedBet, checkedFailedPlacedBet: true };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(placeBet.pending, (state) => ({
        ...state,
        isPlaceBetLoading: true,
        success: false,
      }))
      .addCase(placeBet.fulfilled, (state, { payload }) => {
        let success = false;
        let placeBets = [];
        let placedBetTicketNumber = '';
        let msg = '';
        let failedPlacedBet = false;
        if (payload.every((response) => response[0]['14'])) {
          success = true;
          placeBets = payload.map((response) => response[0].data);
          [placedBetTicketNumber] = payload.map((response) => response[0][2]);
          // Delete placeBet
          localStorage.removeItem('placedBet');
        } else {
          const index = payload.findIndex((response) => response[0]?.msg?.length > 0);
          if (payload[index][0]?.msg) {
            msg = payload[index][0].msg;
          } else {
            msg = payload[0].msg;
          }
          failedPlacedBet = true;
        }

        return {
          ...state,
          placeBets,
          isPlaceBetLoading: false,
          placedBetTicketNumber,
          success,
          failedPlacedBet,
          msg,
          error: '',
          checkedFailedPlacedBet: false,
        };
      })

      .addCase(placeBet.rejected, (state, { payload }) => {
        let msg;
        if (payload && payload.erc && payload.erc === '001') {
          msg = tokenText;
        } else {
          msg = msg29;

          // Delete placeBet
          localStorage.removeItem('placedBet');
        }
        return {
          ...state,
          isPlaceBetLoading: false,
          placedBetTicketNumber: '',
          success: false,
          failedPlacedBet: false,
          msg,
          error: msg29,
          checkedFailedPlacedBet: false,
        };
      });
  },
});

export const { resetSuccess, checkFailedBet } = placeBetsSlice.actions;

export default placeBetsSlice.reducer;
