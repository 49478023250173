// const BASE_URL_API3 = 'http://10.12.1.29:9008';
const BASE_URL_API = 'https://svc2.softtech.test.flexbet-systems.net:14443/mathys-gateway';
const BASE_URL_API_PRINT = 'http://127.0.0.1:18091';
const BASE_URL_IMG = 'https://media-bucket-spt.nyc3.digitaloceanspaces.com/';
const API_VERSION = 'v1';
const API_VERSION2 = 'v2';
const ENCRYPTION_KEY = 'MATHYS_BET_SIGNING$2024';
export default {
  BASE_URL_API, BASE_URL_IMG, API_VERSION, API_VERSION2, BASE_URL_API_PRINT, ENCRYPTION_KEY,
};
