import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { getProviders } from 'redux/deposit/getVoucherProvidersSlice';
import '../../../styles/_home-dashboard.scss';
import PropTypes from 'prop-types';
import { setDashboard } from 'redux/nav-header/navHeader';
import CodeBetForm from '../navigationPanel/CodeBetForm';

const Home = ({ isMobile }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [displayMessage, setDisplayMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const { agentId, privileges } = useSelector((store) => store.login);
  const { homeList } = useSelector((store) => store.homeList);

  const setDashBoardHeader = (title) => {
    dispatch(setDashboard({ name: title, redirect: 'home' }));
  };

  return (
    <>
      <div className="list-dashboard full">
        <div className="content">
          { isMobile && (
          <CodeBetForm setDisplayMessage={setDisplayMessage} setAlertMessage={setAlertMessage} />
          )}
          <ul className="dashboard-grid">
            {homeList?.length > 0 ? homeList.map((option) => {
              if (option.id === 7) {
                return (
                  <NavLink
                    to={`/dashboard/${option.code}`}
                    key={option.id}
                    className="detail-link"
                    onClick={() => {
                      dispatch(getProviders(agentId));
                      setDashBoardHeader(option.name);
                    }}
                  >
                    <div className="home-card">
                      <div className="card-img">
                        <img className="image" src={option.image} alt={t(option.name)} />
                      </div>
                      <h3 className="title">{t(option.name)}</h3>
                    </div>
                  </NavLink>
                );
              }
              if (option.id === 8 && !privileges.retailCustomer) {
                return null;
              }
              return (
                <NavLink
                  to={`/dashboard/${option.code}`}
                  className={`detail-link${option.id === 4 ? ' disable' : ''}`}
                  key={option.id}
                  onClick={() => { setDashBoardHeader(option.name); }}
                >
                  <div className={`home-card${option.id === 4 ? ' disable' : ''}`}>
                    <div className="card-img">
                      <img className="image" src={option.image} alt={t(option.name)} />
                    </div>
                    <h3 className="title">{t(option.name)}</h3>
                  </div>
                </NavLink>
              );
            }) : null}
          </ul>
        </div>
      </div>
      {(displayMessage && isMobile) && (
      <div className="line comb">
        <div className="div-msg comb-msg">
          <span>Info!</span>
          <p>{alertMessage}</p>
        </div>
      </div>
      )}
    </>
  );
};

Home.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default Home;
