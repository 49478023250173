/* eslint  max-len: 0 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { authParams, getBase64Hash } from 'components/helpers/base64AuthParams';
import getLanguage from 'components/helpers/getLanguage';
import translationKeys from 'translations/translationKeys';
import tokenText from 'components/helpers/tokenText';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});
const { msg29, msg40 } = translationKeys;
export const checkWithdraw = createAsyncThunk(
  'Withdrawals/check-withdrawal',
  async (obj, thunkAPI) => {
    const lang = getLanguage();
    const token = JSON.parse(sessionStorage.getItem('token'));
    const requestParams = `lang=${lang}&cac=${obj.cac}&wcd=${obj.wcd}&erf=${obj.erf}&phn=${obj.agentPhone}&uid=${obj.agentId}&str_Pin=${obj.agentPwd}&Ig_Channel_ID=${obj.chl}&ime=${obj.imei}&imei=${obj.imei}`;
    const api = `/ser_check_withdrawal_amount?${requestParams}`;
    const url = `${apiBaseUrl}/${apiVersion2}${api}`;
    const signatureParam = `/ser_check_withdrawal_amount${requestParams}`;
    try {
      const headers = {
        'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}${JSON.stringify({})}`)}`,
        Authorization: token ? `Bearer ${token}` : '',
      };
      const resp = await client.post(url, {}, { headers });
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        message: error.response.data.message || 'An error occurred',
        erc: error.response.data.erc,
      });
    }
  },
);

const initialState = {
  withdrawalChecked: false,
  checkWithdrawLoading: false,
  failedCheckWithdraw: false,
  withDrawalAmount: 0,
  checkMsg: '',
  cac: '',
  wcd: '',
  canWithdraw: false,
  canNotWithdraw: false,
  errorCheckWithdraw: '',
};

const checkWithdrawalSlice = createSlice({
  name: 'withdrawal',
  initialState,
  reducers: {
    hideCheckWithdrawConfirmBox: (state) => ({
      ...state,
      ...initialState,
    }),
  },

  extraReducers: (builder) => {
    builder
      .addCase(checkWithdraw.pending, (state) => ({
        ...state,
        checkWithdrawLoading: true,
      }))
      .addCase(checkWithdraw.fulfilled, (state, { payload, meta }) => {
        let checkMsg = '';
        const checkWithdrawLoading = false;
        let cac = '';
        let wcd = '';
        let canWithdraw = false;
        let canNotWithdraw = false;
        let withDrawalAmount = 0;
        if (Number(payload.vld) === 1) {
          canWithdraw = true;
          cac = meta.arg.cac;
          wcd = meta.arg.wcd;
          withDrawalAmount = payload.amount;
          checkMsg = msg40;
        } else {
          canNotWithdraw = true;
          checkMsg = payload.erc;
        }
        return {
          ...state,
          checkWithdrawLoading,
          checkMsg,
          withDrawalAmount,
          cac,
          wcd,
          canWithdraw,
          canNotWithdraw,
        };
      })

      .addCase(checkWithdraw.rejected, (state, { payload }) => {
        let checkMsg;
        if (payload && payload.erc && payload.erc === '001') {
          checkMsg = tokenText;
        } else { checkMsg = msg29; }
        return {
          ...state,
          checkWithdrawLoading: false,
          failedCheckWithdraw: true,
          checkMsg,
        };
      });
  },
});

export const {
  hideCheckWithdrawConfirmBox,
} = checkWithdrawalSlice.actions;

export default checkWithdrawalSlice.reducer;
