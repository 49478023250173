import crypto from 'crypto-js';

const authParams = {
  RequestHmacKey: 'sportcash',
  username: 'sportcash',
  secret: 'sportcash',
};
const getBase64Hash = (params) => {
  const hash = crypto.HmacSHA256(params, authParams.RequestHmacKey);
  return crypto.enc.Base64.stringify(hash);
};
const parseParams = (data) => {
  const arr = Object.keys(data);
  let queryParams = '';
  for (let i = 0; i < arr.length; i += 1) {
    if (data[arr[i]]) queryParams = `${queryParams + arr[i]}=${data[arr[i]]}${i < arr.length - 1 ? '&' : ''}`;
  }
  return queryParams;
};

export {
  getBase64Hash, authParams, parseParams,
};
