/* eslint  max-len: 0 */
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  addToBetSlip,
  deleteFromBetSlip,
} from 'redux/configs/betConfigsSlice';
import { getEventMarkets } from 'redux/Event-markets/eventMarketSlice';
import formatDateTime from 'components/helpers/formatDateTime';
import { setDetails } from 'redux/nav-header/navHeader';
import chevronRight from '../../../assets/images/mathys/svg/chevron-right.svg';
import baseUrls from '../../../config';

const SportItem = ({ event, redirect, isMobile }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const imgUrl = baseUrls.BASE_URL_IMG;
  const { bets } = useSelector((store) => store.betSlip);
  const { cleanedMarkets } = useSelector((store) => store.topMatchesMkts);
  const { tournament } = useSelector((store) => store.navigationHeader);
  const {
    outcome, info, id, time, number, count,
  } = event;

  const [outComes, setOutComes] = useState(outcome);

  const handleButtonClick = (match, market, uniqueId) => {
    const existingItem = bets.find((item) => item.uniqueId === uniqueId);
    if (existingItem) {
      dispatch(deleteFromBetSlip(uniqueId));
    } else {
      dispatch(addToBetSlip({
        match,
        odds: market.odds,
        team: market.name,
        market: market.market.name,
        matchId: match.id,
        id: market.id,
        uniqueId: market.uniqueId,
        inBetSlip: market.inBetSlip,
        matchNumber: match.number,
        pick: `${match.number}*${market.market.id}*${market.outcome_id}`,
      }));
    }
  };

  const handleMoreButtonClick = () => {
    if (tournament === null) {
      navigate('/dashboard/home');
    } else {
      const name = `${event.info.teamhome.name} VS ${event.info.teamaway.name}`;
      dispatch(setDetails({ name, redirect }));
      dispatch(getEventMarkets(id));
      localStorage.setItem('eventDetails', JSON.stringify(event)); // To be used when page is refreshed to refetch event details
      localStorage.setItem('details-back', redirect);
    }
  };

  useEffect(() => {
    setOutComes(outcome);
  }, [outcome, event]);

  const formattedDate = formatDateTime(time);
  return (
    <>

      {(outComes && outComes.length > 0) ? (
        <li key={event.id + uuidv4()}>
          <div className="match-card">
            {!isMobile && (
            <div className="sport">
              <img src={`${imgUrl}${info.sport.icon}`} alt="" />
            </div>
            )}
            <div className="middle-info">
              <div className="left">
                {isMobile && (
                <div className="sport">
                  <img src={`${imgUrl}${info.sport.icon}`} alt="" />
                </div>
                )}
                <div className="team-info">
                  {!isMobile && (<span className="number">{number}</span>)}
                  {isMobile && (<span className="hour">{formattedDate}</span>)}
                  <span>{info.teamhome.name}</span>
                  <span>{info.teamaway.name}</span>
                </div>
                {!isMobile && (<span className="hour">{formattedDate}</span>)}
                {isMobile && (
                <div className="end-info">
                  <span className="number">{number}</span>
                  <span>
                    +
                    {count}
                  </span>
                  <span>{`${info.category.name} - ${info.tournament.name}`}</span>
                </div>
                )}
                {isMobile && (
                <div className="more-info">
                  <NavLink
                    className="btn btn-more"
                    to="/dashboard/match-details"
                    onClick={handleMoreButtonClick}
                  >
                    <img src={chevronRight} alt="" />
                  </NavLink>
                </div>
                )}
              </div>
              <div className="right">
                <div className="out-comes">
                  {outComes?.length > 0 ? outComes.map((x) => {
                    const itemInBetSlip = bets.find((bet) => bet.uniqueId === x.uniqueId);
                    return (
                      <button
                        key={x.id}
                        type="button"
                        className={itemInBetSlip ? 'btn btn-outcome marked-btn' : 'btn btn-outcome'}
                        onClick={() => {
                          handleButtonClick(event, x, x.uniqueId);
                        }}
                      >
                        <span>
                          {cleanedMarkets[`${x?.market?.id}-${x?.id}`] ? cleanedMarkets[`${x?.market?.id}-${x?.id}`] : x.display }
                        </span>
                        <span className="odd">{x.odds}</span>
                      </button>
                    );
                  }) : null}
                </div>
                {!isMobile && (
                <div className="tournament-info">
                  <span>
                    +
                    {count}
                  </span>
                  <span>-</span>
                  <span>{`${info.category.name} - ${info.tournament.name}`}</span>
                </div>
                )}
              </div>
            </div>
            {!isMobile && (
            <div className="more-info">
              <NavLink
                className="btn btn-more"
                to="/dashboard/match-details"
                onClick={handleMoreButtonClick}
              >
                <img src={chevronRight} alt="" />
              </NavLink>
            </div>
            )}
          </div>
        </li>
      ) : null}

    </>
  );
};
SportItem.propTypes = {
  event: PropTypes.oneOfType([PropTypes.object]).isRequired,
  redirect: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default SportItem;
