import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { authParams, getBase64Hash, parseParams } from 'components/helpers/base64AuthParams';
import translationKeys from 'translations/translationKeys';
import tokenText from 'components/helpers/tokenText';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});

const { msg29 } = translationKeys;

export const resumeBet = createAsyncThunk(
  'bets/ResumeBet',
  async (objects, thunkAPI) => {
    const token = JSON.parse(sessionStorage.getItem('token'));
    const requests = objects.map((obj) => {
      const requestParams = parseParams(obj);
      const api = `/bet/resume?${requestParams}`;
      const url = `${apiBaseUrl}/${apiVersion2}${api}`;
      const signatureParam = `/bet/resume${requestParams}`;
      const headers = {
        'Content-Type': 'application/json; charset=UTF-8',
        'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}${JSON.stringify({})}`)}`,
        Authorization: token ? `Bearer ${token}` : '',
      };
      return client.post(url, {}, { headers });
    });
    try {
      const responses = await Promise.all(requests);
      return responses.map((response) => response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue({
        message: error.response.data.message || 'An error occurred',
        erc: error.response.data.erc,
      });
    }
  },
);

const initialState = {
  tickets: [],
  resumeBets: [],
  resumeBetLoading: false,
  betStatus: '',
  showPreview: false,
  previewMsg: '',
  previewSuccess: false,
};

const resumeBetsSlice = createSlice({
  name: 'resume-bet',
  initialState,
  reducers: {
    showModal: (state) => ({
      ...state,
      showPreview: true,
    }),
    hideModal: (state) => ({
      ...state,
      ...initialState,
    }),
  },

  extraReducers: (builder) => {
    builder
      .addCase(resumeBet.pending, (state) => ({
        ...state,
        resumeBetLoading: true,
      }))
      .addCase(resumeBet.fulfilled, (state, { payload }) => {
        let previewSuccess = false;
        let resumeBets = [];
        let previewMsg = '';
        if (payload.every((response) => response[0]['14'])) {
          previewSuccess = true;
          resumeBets = payload.map((response) => {
            const lines = [...response[0].data];
            const bonusIndex = lines.findIndex((line) => line.includes('Bonus'));
            if (bonusIndex > -1) {
              lines.splice(bonusIndex, 1);
              lines.splice(5, 1, `${lines[5]} - FREE BET`);
            }

            lines.splice(lines.length - 2, 2);
            return lines;
          });
        } else if (payload.some((response) => response[0]?.erc === '0')) {
          previewMsg = msg29;
        } else {
          const index = payload.findIndex((response) => response[0]?.msg?.length > 0);
          if (payload[index][0]?.msg) {
            previewMsg = payload[index][0].msg;
          } else {
            previewMsg = payload[0].msg;
          }
        }
        return {
          ...state,
          resumeBets,
          previewSuccess,
          previewMsg,
          resumeBetLoading: false,
        };
      })

      .addCase(resumeBet.rejected, (state, { payload }) => {
        let previewMsg;
        if (payload && payload.erc && payload.erc === '001') {
          previewMsg = tokenText;
        } else { previewMsg = msg29; }
        return {
          ...state,
          previewMsg,
          resumeBetLoading: false,
        };
      });
  },
});

export const {
  showModal, hideModal, processStack,
} = resumeBetsSlice.actions;

export default resumeBetsSlice.reducer;
