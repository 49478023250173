/* eslint  max-len: 0 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { authParams, getBase64Hash } from 'components/helpers/base64AuthParams';
import getLanguage from 'components/helpers/getLanguage';
import translationKeys from 'translations/translationKeys';
import tokenText from 'components/helpers/tokenText';
import ERROR_MESSAGES from 'utils/error-code';
import { getUserInfo } from 'components/helpers/checkToken';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});
const { msg29 } = translationKeys;
export const bonRecharge = createAsyncThunk(
  'Recharge/bonRecharge',
  async (obj, thunkAPI) => {
    const token = JSON.parse(sessionStorage.getItem('token'));
    const lang = getLanguage();
    const requestParams = `lang=${lang}&uid=${obj.agentId}&ref=${obj.ref}&iref=${obj.iref}&chl=${obj.chl}&ime=${obj.imei}&vers=${obj.ver}&imei=${obj.imei}&pwd=${obj.agentPwd}`;
    const api = `/ser_vente_coupon?${requestParams}`;
    const url = `${apiBaseUrl}/${apiVersion2}${api}`;
    const signatureParam = `/ser_vente_coupon${requestParams}`;
    try {
      const headers = {
        'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}${JSON.stringify({})}`)}`,
        Authorization: token ? `Bearer ${token}` : '',
      };
      const resp = await client.post(url, {}, { headers });
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        message: error.response.data.message || 'An error occurred',
        erc: error.response.data.erc,
      });
    }
  },
);
const transformArray = (originalArray) => {
  const userInfo = getUserInfo();

  return {
    code: originalArray[0].str_Code_Recharge, ref: originalArray[0].str_REF_CODE, amount: Number(originalArray[0].int_Amount), agent: userInfo.agentId, date: originalArray[0].dt_Created,
  };
};

const initialState = {
  bonRechargeData: [],
  isBonRechargeLoading: false,
  bonRechargeSuccess: false,
  failedBonRecharge: false,
  showBonRechargeConfirm: false,
  bonValue: 0,
  errorBonRecharge: '',
};

const bonDepositSlice = createSlice({
  name: 'bon-deposit',
  initialState,
  reducers: {
    showBonRechargeConfirmBox: (state) => ({
      ...state,
      showBonRechargeConfirm: true,
      errorBonRecharge: '',
    }),
    hideBonRechargeConfirmBox: (state) => ({
      ...state,
      showBonRechargeConfirm: false,
      bonRechargeSuccess: false,
      isBonRechargeLoading: false,
      errorBonRecharge: '',
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(bonRecharge.pending, (state) => ({
        ...state,
        isBonRechargeLoading: true,
      }))
      .addCase(bonRecharge.fulfilled, (state, { payload }) => {
        const showBonRechargeConfirm = false;
        let bonRechargeSuccess = false;
        let bonRechargeData = [];
        let errorBonRecharge = '';
        if (payload && payload[0]) {
          if (payload[0].ERROR_CODE === '1' && payload[0].str_RAISON === '01') {
            bonRechargeSuccess = true;
            errorBonRecharge = '';
            bonRechargeData = transformArray(payload);
          } else if (payload[0].ERROR_CODE === '0') {
            bonRechargeSuccess = false;
            const errorCode = payload[0]?.str_RAISON;
            if (errorCode) {
              errorBonRecharge = ERROR_MESSAGES[errorCode] ?? msg29;
            } else {
              errorBonRecharge = msg29;
            }
          }
        }

        return {
          ...state,
          bonRechargeData,
          isBonRechargeLoading: false,
          showBonRechargeConfirm,
          errorBonRecharge,
          bonRechargeSuccess,
          failedBonRecharge: false,
        };
      })
      .addCase(bonRecharge.rejected, (state, { payload }) => {
        let errorBonRecharge;
        if (payload && payload.erc && payload.erc === '001') {
          errorBonRecharge = tokenText;
        } else { errorBonRecharge = msg29; }
        return {
          ...state,
          isBonRechargeLoading: false,
          errorBonRecharge,
        };
      });
  },
});

export const {
  showBonRechargeConfirmBox, hideBonRechargeConfirmBox,
} = bonDepositSlice.actions;

export default bonDepositSlice.reducer;
