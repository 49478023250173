/* eslint  max-len: 0 */

const countOfSpace = (text) => {
  if (typeof text === 'string') {
    const found = text.match(/\s/g);
    return (found?.length ?? 0);
  }
  return 0;
};

export const printTicketData = (ticket) => {
  const charsPerLine = 27;

  if (ticket?.data && Array.isArray(ticket?.data)) {
    const lines = [...ticket.data];
    const bonusIndex = lines.findIndex((line) => line.includes('Bonus'));

    if (bonusIndex > -1) {
      lines.splice(bonusIndex, 1);
      lines.splice(5, 1, `${lines[5]} - FREE BET`);
    }

    const formattedData = lines.map((line, index) => {
      if (line.includes(': ')) {
        const content = line.trim().split(': ');
        let leftSide = content[0].trim();
        let rightSide = content[1].trim();
        const leftSideSpaceCount = countOfSpace(leftSide);
        const rightSideSpaceCount = countOfSpace(rightSide);
        const middleSpaceCount = charsPerLine - leftSide.length - rightSide.length;

        leftSide += ' '.repeat(leftSideSpaceCount);
        rightSide = ' '.repeat(rightSideSpaceCount) + rightSide;

        if (middleSpaceCount > 0) {
          return leftSide + ''.padEnd(middleSpaceCount * 2, ' ') + rightSide;
        }

        if (leftSideSpaceCount > 0 || rightSideSpaceCount > 0) {
          return leftSide + rightSide;
        }

        return `${leftSide}  ${rightSide}`;
      }

      if ((index - 1 >= -1) && (index + 1 < lines.length) && !line.includes('#_')) {
        const topCondition = index === 0 ? true : (lines[index - 1].includes('#_01_#') || lines[index - 1].includes('----------'));

        if (topCondition && lines[index + 1].includes('----------')) {
          const remainingChars = charsPerLine - line.trim().length;
          return ''.padEnd(remainingChars, ' ') + line.trim() + ''.padStart(remainingChars, ' ');
        }
      }

      if (line.includes('----------')) {
        return '-'.padEnd(charsPerLine + 3, '-');
      }

      return line.trim();
    });

    if (!formattedData.some((line) => line.includes('#_01_#'))) {
      formattedData.unshift('#_01_#');
    }

    // remove the two last lines that content the flexbet signature
    formattedData.splice(formattedData.length - 2, 2);

    formattedData.push('  ', '  ');

    return window.Android && window.Android.printTicket(JSON.stringify({ ...ticket, data: formattedData }));
  }

  return null;
};

export const scan = () => window.Android && window.Android.scanCode();

export const getDeviceImei = () => (window.Android && window.Android.getDeviceImei());
