/* eslint  max-len: 0 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { authParams, getBase64Hash } from 'components/helpers/base64AuthParams';
import getLanguage from 'components/helpers/getLanguage';
import translationKeys from 'translations/translationKeys';
import tokenText from 'components/helpers/tokenText';
import ERROR_MESSAGES from 'utils/error-code';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});
const { msg29 } = translationKeys;
export const directRecharge = createAsyncThunk(
  'Recharge/directRecharge',
  async (obj, thunkAPI) => {
    const token = JSON.parse(sessionStorage.getItem('token'));
    const lang = getLanguage();
    const requestParams = `lang=${lang}&agt=${obj.agentId}&amt=${obj.amt}&chl=${obj.chl}&acc=${obj.acc}&ime=${obj.imei}&vers=${obj.ver}&pwd=${obj.agentPwd}`;
    const api = `/ser_recharge_compte_client?${requestParams}`;
    const url = `${apiBaseUrl}/${apiVersion2}${api}`;
    const signatureParam = `/ser_recharge_compte_client${requestParams}`;
    try {
      const headers = {
        'Content-Type': 'application/json; charset=UTF-8',
        'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}${JSON.stringify(obj)}`)}`,
        Authorization: token ? `Bearer ${token}` : '',
      };
      const resp = await client.post(url, obj, { headers });
      return resp.data;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.erc) {
        return thunkAPI.rejectWithValue({
          message: error.response.data.message || 'An error occurred',
          erc: error.response.data.erc,
        });
      }
      return thunkAPI.rejectWithValue({
        message: 'An error occurred',
        erc: null,
      });
    }
  },
);

const transformArray = (obj) => ({
  account: obj.acc, amount: Number(obj.rmt), ref: obj.ref, agent: obj.agt, date: obj.dte,
});

const initialState = {
  depositData: [],
  isdepositLoading: false,
  rechargeSuccess: false,
  failedRecharge: false,
  showRechargeConfirm: false,
  rechargeValue: 0,
  directRechargeMsg: '',
  errorRecharge: '',
};

const depositSlice = createSlice({
  name: 'make deposit',
  initialState,
  reducers: {
    showRechargeConfirmBox: (state, { payload }) => ({
      ...state,
      showRechargeConfirm: true,
      rechargeValue: payload,
      isdepositLoading: false,
      errorRecharge: '',
    }),
    hideRechargeConfirmBox: (state) => ({
      ...state,
      showRechargeConfirm: false,
      rechargeSuccess: false,
      isdepositLoading: false,
      failedRecharge: false,
      errorRecharge: '',
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(directRecharge.pending, (state) => ({
        ...state,
        isdepositLoading: true,
        showRechargeConfirm: false,
      }))
      .addCase(directRecharge.fulfilled, (state, { payload }) => {
        let rechargeSuccess = false;
        let showRechargeConfirm = false;
        let errorRecharge = '';
        let failedRecharge = false;
        let depositData = [];
        let response = payload;
        if (typeof response === 'string') {
          response = JSON.parse(response.split('[')[0]);
        }
        if (response?.vld) {
          if (response.vld === '1') {
            rechargeSuccess = true;
            depositData = transformArray(response);
          } else if (response?.erc) {
            failedRecharge = true;
            showRechargeConfirm = true;
            errorRecharge = ERROR_MESSAGES[response.erc] ?? msg29;
          }
        }

        return {
          ...state,
          depositData,
          isdepositLoading: false,
          rechargeSuccess,
          showRechargeConfirm,
          errorRecharge,
          failedRecharge,
        };
      })
      .addCase(directRecharge.rejected, (state, { payload }) => {
        let errorRecharge;
        if (payload && payload.erc && payload.erc === '001') {
          errorRecharge = tokenText;
        } else { errorRecharge = msg29; }
        return {
          ...state,
          isdepositLoading: false,
          showRechargeConfirm: true,
          errorRecharge,
        };
      });
  },
});

export const { showRechargeConfirmBox, hideRechargeConfirmBox } = depositSlice.actions;
export default depositSlice.reducer;
