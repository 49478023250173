import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import translationKeys from 'translations/translationKeys';
import PropTypes from 'prop-types';
import { logout } from 'redux/login/loginSlice';
import ResetPinComponent from 'components/sessions/ResetPinComponent';
import getLanguage from 'components/helpers/getLanguage';
import { IoMdClose } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import backIcon from '../../../assets/images/mathys/svg/chevron-left.svg';

const MenuProfile = ({ open, close, isMobile }) => {
  const { t, i18n } = useTranslation();
  const [toggleResetPin, setToggleResetPin] = useState(false);
  const [toggleChangeLangPin, setToggleChangeLang] = useState(false);
  const [language, setLanguage] = useState(getLanguage() ?? navigator.language.split('-')[0]);
  const {
    msg101, msg170, msg171, msg172, msg173, msg174, msg175,
  } = translationKeys;

  const dispatch = useDispatch();

  const closeResetPin = () => {
    setToggleResetPin(false);
  };

  const chooseLanguage = (event) => {
    setLanguage(event.target.value);
  };

  const handleLanguageChange = () => {
    i18n.changeLanguage(language);
    localStorage.setItem('lang', language);
    setToggleChangeLang(false);
  };

  const iconStyle = {
    color: '#777777',
    fontSize: '32px',
  };

  return (
    <>
      <div className={`account-setting-container${open ? ' open' : ''}`}>
        <div className="menu-profile">
          <button className="btn btn-back" type="button" onClick={close}>
            <span className="icon" style={{ maskImage: `url(${backIcon})` }} />
            <span>{t(msg170)}</span>
          </button>
          <div className="menu-content">
            <button className="btn btn-menu" type="button" onClick={() => { setToggleResetPin(true); }}>{t(msg101)}</button>
            <button className="btn btn-menu" type="button" onClick={() => { setToggleChangeLang(true); }}>{t(msg171)}</button>
            <button className="btn btn-menu text-danger" type="button" onClick={() => { dispatch(logout()); }}>{t(msg172)}</button>
          </div>
        </div>
        <ResetPinComponent containerClass="reset-pin-container" open={toggleResetPin} close={closeResetPin} isMobile={isMobile} />
      </div>
      { toggleChangeLangPin && isMobile && (
      <div className={toggleChangeLangPin ? 'modalContainer' : 'go-back-to-top'}>
        <div className="lang-modal">
          <div className="lang-form">
            <h4 className="title">{t(msg171)}</h4>
            <div className="radio-option">
              <input type="radio" name="lang-tab" id="french-tab" value="fr" defaultChecked={language === 'fr'} onChange={chooseLanguage} />
              <label htmlFor="french-tab">{t(msg173)}</label>
            </div>
            <div className="radio-option">
              <input type="radio" name="lang-tab" id="english-tab" value="en" defaultChecked={language === 'en'} onChange={chooseLanguage} />
              <label htmlFor="profile-tab">{t(msg174)}</label>
            </div>
            <div className="actions">
              <button className="btn btn-success" type="button" onClick={handleLanguageChange}>{t(msg175)}</button>
              <button className="btn btnClose" type="button" onClick={() => { setToggleChangeLang(false); }}><IoMdClose style={iconStyle} /></button>
            </div>
          </div>
        </div>
      </div>
      )}
    </>
  );
};

MenuProfile.propTypes = {
  open: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default MenuProfile;
