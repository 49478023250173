/* eslint max-len: 0 */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import defaultSportId from 'components/helpers/defaultSport';
import '../../../../styles/_sports.scss';
import Loading from 'components/common/Loading';
import Error from 'components/common/Error';
import { getTodayMatches } from 'redux/today-matches/todayMatchesSlice';
import translationKeys from 'translations/translationKeys';
import getSortedMatches from 'components/helpers/sortedMatches';
import { selectSport } from 'redux/all-sports/allSportsSLice';
import PropTypes from 'prop-types';
import SportItem from '../SportItem';
import defaultImage from '../../../../assets/images/mathys/png/football.png';
import baseUrls from '../../../../config';

const TodayMatches = ({ isMobile }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const imgUrl = baseUrls.BASE_URL_IMG;
  const navigate = useNavigate();
  const selectedTodaySport = JSON.parse(localStorage.getItem('selectedTodaySport')) ? JSON.parse(localStorage.getItem('selectedTodaySport')) : defaultSportId; // Set in Login.jsx.
  const { msg154 } = translationKeys;
  const {
    todayMatches, successTodayMatches, isLoading, error,
  } = useSelector((store) => store.todayMatches);
  const { allSports } = useSelector((store) => store.allSports);
  const [sortedMatches, setSortedMatches] = useState([]);
  const [selectedSport, setSelectedSport] = useState(selectedTodaySport);
  // When page is refreshed and path is /dashboard/today-matches, call today matches so data is not lost.
  useEffect(() => {
    if (!successTodayMatches) {
      dispatch(getTodayMatches(selectedSport));
      dispatch(selectSport(selectedSport));
    }

    if (successTodayMatches) {
      setSortedMatches(getSortedMatches(todayMatches));
    }
  }, [successTodayMatches, selectedTodaySport, todayMatches, dispatch]);
  return (

    <div className="list-dashboard">
      <div className="content-wrap">
        <div className="tab-content">
          <div className="zone-nav">
            <ul className="sport-list list-inline">
              {allSports?.length > 0 ? allSports.map((item) => item && (
              <li className={`nav-item sport list-inline-item ${item.id === selectedSport ? ' active' : ''}`} key={item.id}>
                <button
                  type="button"
                  className="nav-link"
                  onClick={() => {
                    setSelectedSport(item.id);
                    dispatch(getTodayMatches(item.id));
                    localStorage.setItem('selectedTodaySport', JSON.stringify(item.id)); // To be used when page is refreshed to refetch today matches
                    navigate('/dashboard/today-matches');
                  }}
                >
                  <img
                    src={imgUrl + item.icon || defaultImage}
                    alt={item.name}
                  />
                  <span>{item.name}</span>
                </button>
              </li>
              )) : null}
            </ul>
          </div>
          <div className="tab-content list">
            {isLoading ? <Loading /> : null}
            {error && !isLoading ? <Error text={t(error)} /> : null}
            {!isLoading && !error && (
            <ul className="match-list">
              { sortedMatches.length > 0 ? sortedMatches.map((match) => <SportItem event={match} isMobile={isMobile} key={match.id + uuidv4()} redirect="/dashboard/today-matches" />) : <Error text={t(msg154)} /> }
            </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

TodayMatches.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default TodayMatches;
