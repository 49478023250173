import React from 'react';
import PropTypes from 'prop-types';
import TopBar from './TopBar';
import Body from './Body';

const NavigationPanel = ({ isMobile }) => (
  <>
    <TopBar isMobile={isMobile} />
    <Body isMobile={isMobile} />
  </>
);

NavigationPanel.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default NavigationPanel;
