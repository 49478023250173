import translationKeys from './translationKeys';

const fr = {
  [translationKeys.msg1]: 'Connexion',
  [translationKeys.msg2]: 'Code agent',
  [translationKeys.msg3]: 'Code PIN',
  [translationKeys.msg4]: 'Entrer le code',
  [translationKeys.msg5]: 'Entrez votre PIN',
  [translationKeys.msg6]: 'Le code agent ne doit pas être vide',
  [translationKeys.msg7]: 'Code PIN ne doit pas être vide',
  [translationKeys.msg8]: 'Afficher le code PIN ?',
  [translationKeys.msg9]: 'Tendances chaudes',
  [translationKeys.msg10]: 'Aujourd\'hui',
  [translationKeys.msg11]: 'Meilleures Compétitions',
  [translationKeys.msg12]: 'Tableau de bord',
  [translationKeys.msg13]: 'Sports',
  [translationKeys.msg14]: 'Jackpot',
  [translationKeys.msg15]: 'Pays',
  [translationKeys.msg16]: 'Vérifier ticket',
  [translationKeys.msg17]: 'Historique',
  [translationKeys.msg18]: 'Recherche',
  [translationKeys.msg19]: 'Retrait',
  [translationKeys.msg20]: 'Journal de caisse',
  [translationKeys.msg21]: 'Dépôt',
  [translationKeys.msg22]: 'Chargement...',
  [translationKeys.msg23]: 'Erreur de connexion, veuillez contacter le support !',
  [translationKeys.msg24]: 'Pin Expiré ! Vous devez le changer',
  [translationKeys.msg25]: 'Agent Inconnu',
  [translationKeys.msg26]: 'Vous n êtes pas authorisé à vous connecter via ce périphérique',
  [translationKeys.msg27]: 'Pin Agent Incorrect',
  [translationKeys.msg28]: 'Agent Suspendu',
  [translationKeys.msg29]: 'Une erreur est survenue sur le serveur',
  [translationKeys.msg30]: 'Pin Changé avec Succes',
  [translationKeys.msg31]: 'Votre Pin ne doit pas contenir de chiffres consécutifs',
  [translationKeys.msg32]: 'Deux chiffres ont été répétés dans votre pin',
  [translationKeys.msg33]: 'Le pari n\'est pas enregistre sur le serveur',
  [translationKeys.msg34]: 'Pari deja annulé',
  [translationKeys.msg35]: 'Pari Annulé avec succes',
  [translationKeys.msg36]: 'L\'annulation du pari n\'a pas été effectuée, veuillez reprendre l\'opération',
  [translationKeys.msg37]: 'Ticket payé avec succès',
  [translationKeys.msg38]: 'Délai d\'annulation expiré',
  [translationKeys.msg39]: 'Vous n\'avez pas le priviliegé d\'effectuer cette action.',
  [translationKeys.msg40]: 'Ce montant ({{amount}}) est disponible',
  [translationKeys.msg41]: 'Veuillez entrer une combinaison valide.',
  [translationKeys.msg42]: 'l\'élément existe déjà.',
  [translationKeys.msg43]: 'Êtes-vous sûr de vouloir payer ce ticket ?',
  [translationKeys.msg44]: 'Êtes-vous sûr de vouloir annuler ce ticket ?',
  [translationKeys.msg45]: 'Confirmer la vente du coupon de {{amount}}',
  [translationKeys.msg46]: 'succès!',
  [translationKeys.msg47]: 'Retrait effectué avec succès, veuillez imprimer le ticket.',
  [translationKeys.msg48]: 'Patientez s\'il vous plait...',
  [translationKeys.msg49]: 'valider',
  [translationKeys.msg50]: 'Payer',
  [translationKeys.msg51]: 'Annuler',
  [translationKeys.msg52]: 'Oui',
  [translationKeys.msg53]: 'Imprimer',
  [translationKeys.msg54]: 'Montant',
  [translationKeys.msg55]: 'Soumettre',
  [translationKeys.msg56]: 'Code client',
  [translationKeys.msg57]: 'Tickets placés',
  [translationKeys.msg58]: 'Tickets annulés',
  [translationKeys.msg59]: 'Tickets payés',
  [translationKeys.msg60]: 'Recharge directe',
  [translationKeys.msg61]: 'Vente coupon',
  [translationKeys.msg62]: 'Date de début',
  [translationKeys.msg63]: 'Date',
  [translationKeys.msg64]: 'Type',
  [translationKeys.msg65]: 'Ref Code',
  [translationKeys.msg66]: 'TransID',
  [translationKeys.msg67]: 'Aucune entrée disponible dans la plage sélectionnée.',
  [translationKeys.msg68]: 'Date de fin',
  [translationKeys.msg69]: 'Historique des détails',
  [translationKeys.msg70]: 'VÉRIFIER TICKET',
  [translationKeys.msg71]: 'Numéro de ticket',
  [translationKeys.msg72]: 'Code du client',
  [translationKeys.msg73]: 'Code OTP',
  [translationKeys.msg74]: 'Tout effacer',
  [translationKeys.msg75]: 'Mise',
  [translationKeys.msg76]: 'Gain potentiel',
  [translationKeys.msg77]: 'Mise Totale',
  [translationKeys.msg78]: 'Accepter les changements de côtes',
  [translationKeys.msg79]: 'Placer mon pari',
  [translationKeys.msg80]: 'Déconnexion',
  [translationKeys.msg81]: 'Modifier le PIN',
  [translationKeys.msg82]: 'Combinaison',
  [translationKeys.msg83]: 'Code coupon',
  [translationKeys.msg84]: 'Entrer le code du client',
  [translationKeys.msg85]: 'Entrer le code OTP',
  [translationKeys.msg86]: 'Ticket placé',
  [translationKeys.msg87]: 'Ticket annulé',
  [translationKeys.msg88]: 'Ticket payé',
  [translationKeys.msg89]: 'Le code client est incorrect',
  [translationKeys.msg90]: 'Le montant est supérieur au solde de l agent',
  [translationKeys.msg91]: 'La Transaction a echoué',
  [translationKeys.msg92]: 'Aucune entrée disponible',
  [translationKeys.msg93]: 'Resultat',
  [translationKeys.msg94]: 'Total de buts',
  [translationKeys.msg95]: 'But d\'équipe',
  [translationKeys.msg96]: 'Buteur',
  [translationKeys.msg97]: 'Différence de but',
  [translationKeys.msg98]: 'Score',
  [translationKeys.msg99]: 'Le nouveau code PIN ne doit pas être vide',
  [translationKeys.msg100]: 'Le nouveau Pin doit être différent de l\'ancien',
  [translationKeys.msg101]: 'Modifiez votre code PIN',
  [translationKeys.msg102]: 'Ancien code PIN',
  [translationKeys.msg103]: 'Nouveau code PIN',
  [translationKeys.msg104]: 'Confirmer le nouveau code PIN',
  [translationKeys.msg105]: 'Pas de Ticket existant avec cette reference.',
  [translationKeys.msg106]: 'Ticket Perdant.',
  [translationKeys.msg107]: 'Gain dés payés.',
  [translationKeys.msg108]: 'Ticket expir&eacute; !!',
  [translationKeys.msg109]: 'Client inconnu !!',
  [translationKeys.msg110]: 'Erreur lors de la validation: Ce code n\'est pas disponible !',
  [translationKeys.msg111]: 'Code retrait déja expiré !',
  [translationKeys.msg112]: 'Code retrait déja déboursé !',
  [translationKeys.msg113]: 'la generation du code pin nest pas definie',
  [translationKeys.msg114]: 'Parametre incomplet',
  [translationKeys.msg115]: 'Le montant des ventes de la carte n est pas défini sur le serveur',
  [translationKeys.msg116]: 'Autorisation échouée',
  [translationKeys.msg117]: 'Code inconnu',
  [translationKeys.msg118]: 'Agent inconnu',
  [translationKeys.msg119]: 'Le stock est vide',
  [translationKeys.msg120]: 'Le montant est supérieur au solde de l\'agent',
  [translationKeys.msg121]: 'Recharge directe',
  [translationKeys.msg122]: 'Les côtes ne peuvent pas dépasser',
  [translationKeys.msg123]: 'Le nombre d\'événements ne peut pas dépasser',
  [translationKeys.msg124]: 'Le nombre maximal d\'événements pour le bonus ne peut pas dépasser',
  [translationKeys.msg125]: 'La mise ne peut pas dépasser',
  [translationKeys.msg126]: 'Les gains ne peuvent pas dépasser',
  [translationKeys.msg127]: 'Code retrait suspendu !',
  [translationKeys.msg128]: 'Ce compte est bloqué !',
  [translationKeys.msg129]: 'Ce compte est désactivé !',
  [translationKeys.msg130]: 'Operation non autorisee!',
  [translationKeys.msg131]: 'Périphérique Non Assigner',
  [translationKeys.msg132]: 'Votre session a expiré. Veuillez vous reconnecter.',
  [translationKeys.msg133]: 'Bons',
  [translationKeys.msg134]: 'Entrer le montant',
  [translationKeys.msg136]: 'Veuillez confirmer le nouveau code PIN',
  [translationKeys.msg137]: 'Les codes PIN ne correspondent pas',
  [translationKeys.msg138]: 'Votre vente de credit a eté suspendu .Contactez l administrateur',
  [translationKeys.msg139]: 'Aucun résultat pour l\'événement',
  [translationKeys.msg140]: 'Coupon introuvable!',
  [translationKeys.msg141]: 'Canal non alloué a cet agent',
  [translationKeys.msg142]: 'Le coupon est expiré!',
  [translationKeys.msg143]: 'Veuillez entrer un coupon valide.',
  [translationKeys.msg144]: 'Montant du pari invalide.',
  [translationKeys.msg145]: 'Mise après impôt',
  [translationKeys.msg146]: 'Impôt',
  [translationKeys.msg147]: 'Paramètre manquant!',
  [translationKeys.msg148]: 'Utilisateur inconnu',
  [translationKeys.msg149]: 'Gain final',
  [translationKeys.msg150]: 'Vous devrez attendre 1 mins apres le dernier depot pour effectuer un autre',
  [translationKeys.msg151]: 'Rechercher',
  [translationKeys.msg152]: 'Entrer votre recherche',
  [translationKeys.msg153]: 'MES SÉLECTIONS',
  [translationKeys.msg154]: 'Actuellement, aucun jeu n\'est disponible. Veuillez réessayer ultérieurement.',
  [translationKeys.msg155]: 'Dépôt échoué.',
  [translationKeys.msg156]: 'La mise ne peut pas être inférieure à',
  [translationKeys.msg157]: 'CÔTE TOTALE',
  [translationKeys.msg158]: 'Gain Pot.',
  [translationKeys.msg159]: 'Une erreur est survenue avec l\'imprimante',
  [translationKeys.msg160]: 'Paris intégraux non autorisés',
  [translationKeys.msg161]: 'La mise doit être un multiple de',
  [translationKeys.msg162]: 'Gains Totaux',
  [translationKeys.msg163]: 'Page introuvable',
  [translationKeys.msg164]: 'La page que vous recherchez n’existe pas ou a été déplacée.',
  [translationKeys.msg165]: 'Allez à la page d’accueil',
  [translationKeys.msg166]: 'Le nombre d\'événements pour ce pari doit être d\'au moins',
  [translationKeys.msg167]: 'Le nombre d\'événements pour ce pari doit être au maximum de',
  [translationKeys.msg168]: 'Entrer la combinaison',
  [translationKeys.msg169]: 'Entrer le code du coupon',
  [translationKeys.msg170]: 'Menu du profil',
  [translationKeys.msg171]: 'Changer la langue',
  [translationKeys.msg172]: 'Se déconnecter',
  [translationKeys.msg173]: 'Français',
  [translationKeys.msg174]: 'Anglais',
  [translationKeys.msg175]: 'Enregistrer',
  [translationKeys.msg176]: 'Entrez votre code PIN actuel',
  [translationKeys.msg177]: 'Entrer le nouveau code PIN',
  [translationKeys.msg178]: 'Sélectionnez un filtre',
  [translationKeys.msg179]: 'Vous avez atteint le nombre maximum de transactions quotidiennes',
  [translationKeys.msg180]: 'Ce produit ne vous est pas attribué',
  [translationKeys.msg181]: 'Détails',
  [translationKeys.msg182]: 'Création de compte',
  [translationKeys.msg183]: 'Création de compte client',
  [translationKeys.msg184]: 'Activation de compte',
  [translationKeys.msg185]: 'Numéro de téléphone',
  [translationKeys.msg186]: 'Validation du compte',
  [translationKeys.msg187]: 'Numéro de téléphone non valide',
  [translationKeys.msg188]: 'Compte créé avec succès ! Veuillez demander au client de vérifier ses SMS pour activer son compte.',
  [translationKeys.msg189]: 'Entrer le code de confirmation envoyé au numero',
  [translationKeys.msg190]: 'Vous n’avez pas reçu le code ?',
  [translationKeys.msg191]: 'Renvoyer',
  [translationKeys.msg192]: 'Code de confirmation',
  [translationKeys.msg193]: 'Operation effectuée avec succès',
  [translationKeys.msg194]: 'Ce compte est désormais actif',
  [translationKeys.msg195]: 'jj/mm/aaaa',
  [translationKeys.msg196]: 'Un ticket contenant le QR code du compte a été généré, veuillez l’imprimer pour clôturer le processus.',
  [translationKeys.msg197]: 'TEL',
  [translationKeys.msg198]: 'CODE DU COMPTE',
  [translationKeys.msg199]: 'mm/jj/aaaa',
  [translationKeys.msg200]: 'Code d’activation de compte erroné',
  [translationKeys.msg201]: 'Parier pour un client',
  [translationKeys.msg202]: 'Utiliser le bonus agent',
  [translationKeys.msg203]: 'Code du client',
  [translationKeys.msg204]: 'Numéro de tél.',
  [translationKeys.msg205]: 'Entrer le code du client',
  [translationKeys.msg206]: 'Code client non valide',
  [translationKeys.msg207]: 'Entrer le code du bonus',
  [translationKeys.msg208]: 'Confirmer',
  [translationKeys.msg209]: 'Utilisateur inconnu',
  [translationKeys.msg210]: 'Sélectionner un bonus',
  [translationKeys.msg211]: 'Utiliser le bonus client',
  [translationKeys.msg212]: 'Cet agent a été suspendu',
  [translationKeys.msg213]: 'La mise du pari doit être égale au montant du bonus',
  [translationKeys.msg214]: 'Type de Device inconnu',
  [translationKeys.msg215]: 'Il y a un pari qui a échoué et qu’il faut annuler.',
  [translationKeys.msg216]: 'Il y a un ticket qui n’a pas pu être imprimé.',
  [translationKeys.msg217]: 'Le numéro de téléphone est requis',
  [translationKeys.msg218]: 'Entrez votre numéro de téléphone',
  [translationKeys.msg219]: 'Mauvaise identification de l\'utilisateur',
  [translationKeys.msg220]: 'L\'imprimante n\'est pas connectée',
  [translationKeys.msg221]: 'N° DE COMPTE',
  [translationKeys.msg222]: 'RECHARGE  COUPON',
  [translationKeys.msg223]: 'CODE RECHARGE',
  [translationKeys.msg224]: 'Erreur survenue pendant l\'impression',
  [translationKeys.msg225]: 'Impossible de vous identifier',
  [translationKeys.msg226]: 'Impression en cours...',
  [translationKeys.msg227]: 'Imprimez le ticket de recharge',
  [translationKeys.msg228]: 'Code invalide',
  [translationKeys.msg229]: 'Une erreur s\'est produite pendant le processus',
  [translationKeys.msg230]: 'Confirmer la recharge de {{amount}}',
  'resend-code': 'Renvoyer dans <1>{{seconds}}</1> sec',
};

export default fr;
