/* eslint  max-len: 0 */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { login } from 'redux/login/loginSlice';
import { userObj, webimei } from 'components/common/requestBody';
import defaultSportId from 'components/helpers/defaultSport';
import translationKeys from 'translations/translationKeys';
import Modal from 'components/common/Modal';
import LoginModal from 'components/common/LoginModal';
import ResetPinComponent from 'components/sessions/ResetPinComponent';
import isNativeApp from 'components/helpers/native';
import PropTypes from 'prop-types';
import logo from '../../assets/images/mathys/png/logo.png';

const Login = ({ isMobile }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const {
    isLoading, success, loginMessage, resetPinMessage,
  } = useSelector((store) => store.login);
  const {
    resetPinForm,
    resetLoading, resetMessage,
    resetSuccessMsg,
  } = useSelector((store) => store.resetPinData);
  const [isShown, setIsShown] = useState(false);
  const [language, setLanguage] = useState(navigator.language.split('-')[0]);
  const closeButton = true;

  const {
    msg6, msg5, msg1, msg7, msg2, msg4, msg3, msg8, msg9,
  } = translationKeys;
  const formik = useFormik({
    initialValues: {
      channel: userObj.chl,
      str_mat: '',
      str_pin: '',
      DT: userObj.DT,
      str_version: userObj.ver,
      channel_id: userObj.chl,
      str_phone: isNativeApp() ? '' : webimei,
    },
    validationSchema: Yup.object({
      str_mat: Yup.string().required(t(msg6)),
      str_pin: Yup.string().required(t(msg7)),
    }),
    onSubmit: (values) => {
      dispatch(login(values));
    },
  });

  const togglePassword = () => {
    setIsShown(!isShown);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
  };

  const handleLanguageChange = (e) => {
    changeLanguage(e.target.value);
    localStorage.setItem('lang', e.target.value);
  };

  useEffect(() => {
    if (success && !isLoading) {
      localStorage.setItem('buttonId', JSON.stringify(msg9)); // Set active button to Top matches
      localStorage.setItem('selectedTodaySport', JSON.stringify(defaultSportId)); // Set default sport for today matches
      navigate('/dashboard/home');
    }
  }, [isLoading, success]);

  useEffect(() => {
    if (resetPinForm) {
      formik.resetForm({
        channel: userObj.chl,
        str_mat: '',
        str_pin: '',
        DT: userObj.DT,
        str_version: userObj.ver,
        channel_id: userObj.chl,
        str_phone: isNativeApp() ? '' : webimei,
      });
    }
  }, [resetPinForm]);

  useEffect(() => {
    formik.setErrors({});
    formik.setTouched({});
  }, [language]);

  return (
    <>
      <div className="login">
        <div className="header">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
          <div className="language">
            <select className="form-select" defaultValue={language} onChange={handleLanguageChange}>
              <option value="fr">FR</option>
              <option value="en">EN</option>
            </select>
          </div>
        </div>
        <div className="content">
          <div className="panel">
            <h1 className="title">{t(msg1)}</h1>
            <form onSubmit={formik.handleSubmit}>
              <div className="ctn-input">
                <label htmlFor="str_mat" className="form-label">
                  {t(msg2)}
                </label>
                <input
                  type="text"
                  className="form-control input_image login-code"
                  id="str_mat"
                  placeholder={t(msg4)}
                  aria-describedby="str_mat"
                  value={success ? '' : formik.values.str_mat}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.str_mat && formik.errors.str_mat ? (
                  <div id="str_mat" className="form-text">{formik.errors.str_mat}</div>

                ) : null}
              </div>
              <div className="ctn-input">
                <label htmlFor="str_pin" className="form-label">
                  {t(msg3)}
                </label>
                <input
                  type={isShown ? 'text' : 'password'}
                  className="form-control input_image login-pin"
                  id="str_pin"
                  placeholder={t(msg5)}
                  autoComplete={String(!isShown)}
                  name="str_pin"
                  value={success ? '' : formik.values.str_pin}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.str_pin && formik.errors.str_pin ? (
                  <div id="astr_pin" className="form-text">{formik.errors.str_pin}</div>

                ) : null}
              </div>
              <div className="ctn-input checkbox">
                <label htmlFor="checkbox" id="show-password">
                  <span>{t(msg8)}</span>
                  <input
                    type="checkbox"
                    id="checkbox"
                    checked={isShown}
                    onChange={togglePassword}
                  />
                </label>
              </div>
              <button
                type="submit"
                className="btn btn-secondary"
                disabled={isLoading}
              >
                {isLoading && (
                <span className="spinner-border spinner-border-sm" />
                )}
                <span>{t(msg1)}</span>
              </button>
            </form>
          </div>
        </div>
      </div>
      {/* These modals are placed here because they're rendered when user has not accessed the dashboard yet */}
      {(loginMessage || resetPinMessage) && <LoginModal />}
      {(resetPinForm) && <ResetPinComponent open={resetPinForm} isMobile={isMobile} containerClass={isMobile ? 'reset-pin-container expired' : 'modalContainer'} closeButton={closeButton && !isMobile} />}
      {(resetLoading || resetMessage || resetSuccessMsg) && <Modal />}
    </>
  );
};

Login.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default Login;
