import ticket from './assets/images/mathys/svg/ticket.svg';
import clock from './assets/images/mathys/svg/clock.svg';
import search from './assets/images/mathys/svg/search.svg';
import withdrawal from './assets/images/mathys/svg/withdrawal.svg';
import solde from './assets/images/mathys/svg/solde.svg';
import deposit from './assets/images/mathys/svg/deposit.svg';
import accountCreation from './assets/images/mathys/svg/account-creation.svg';

const homeData = [
  {
    id: 1,
    name: 'Vérifier ticket',
    code: 'verifier-ticket',
    image: ticket,
  },
  {
    id: 2,
    name: 'Historique',
    code: 'history',
    image: clock,
  },
  {
    id: 7,
    name: 'Dépôt',
    code: 'deposit',
    image: deposit,
  },
  {
    id: 5,
    name: 'Retrait',
    code: 'withdrawal',
    image: withdrawal,
  },
  {
    id: 6,
    name: 'Journal de caisse',
    code: 'solde',
    image: solde,
  },
  {
    id: 8,
    name: 'Création de compte',
    code: 'account-creation',
    image: accountCreation,
  },
  {
    id: 4,
    name: 'Recherche',
    code: 'search',
    image: search,
  },
];

const sportsData = [];

export { homeData, sportsData };
