/* eslint  max-len: 0 */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import '../../../styles/_sports.scss';
import { getTournamentMatches } from 'redux/tournament-matches/tournamentMatchesSlice';
import translationKeys from 'translations/translationKeys';
import Loading from 'components/common/Loading';
import Error from 'components/common/Error';
import PropTypes from 'prop-types';
import SportItem from './SportItem';

const UniversalComponent = ({ isMobile }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { msg154 } = translationKeys;
  const sportSelected = JSON.parse(localStorage.getItem('sportSelected')); // Set in App.jsx
  const {
    tournamentMatches, isLoading, error, successGetTournamentMatches,
  } = useSelector((store) => store.tournamentMatches);
  const { topMatchesMkts } = useSelector((store) => store.topMatchesMkts);
  let marketNames;
  if (topMatchesMkts && topMatchesMkts[sportSelected]) {
    const sport1Markets = topMatchesMkts[sportSelected];
    marketNames = sport1Markets.map((item) => JSON.parse(item));
  }
  const tournamentId = JSON.parse(localStorage.getItem('tournamentId')); // Set in LeftSideBar.jsx
  let marketId = localStorage.getItem('marketId'); // Set below
  // When page is refreshed and path is /dashboard/matches, call tournament matches.
  useEffect(() => {
    if (!successGetTournamentMatches && pathname === '/dashboard/matches') {
      dispatch(getTournamentMatches({ tournamentId, marketId: marketId || '' }));
      if (marketId === null) {
        marketId = 1;
        localStorage.setItem('marketId', marketId);
      }
    }
  }, [pathname, successGetTournamentMatches, dispatch]);
  return (
    <div className="list-dashboard">
      <div className="content-wrap">
        <div className="tab-content">
          <div className="zone-nav">
            <ul className="market-list">
              {marketNames?.length > 0 ? marketNames.map((item) => item && (
              <li className={`nav-item list-inline-item${marketId === item.marketId ? ' active' : ''}`} role="presentation" key={uuidv4()}>
                <button
                  type="button"
                  className="nav-link"
                  onClick={() => {
                    // localStorage.setItem('marketSelected', JSON.stringify(item));
                    localStorage.setItem('marketId', item.marketId); // To be used refetch tournament matches when page is refreshed
                    dispatch(getTournamentMatches({ tournamentId, marketId: item.marketId }));
                    navigate('/dashboard/matches');
                  }}
                >
                  {item.marketName}
                </button>
              </li>
              )) : null}
            </ul>
          </div>
          <div className="tab-content list">
            {isLoading ? <Loading /> : null}
            {error && !isLoading ? <Error text={t(error)} /> : null}
            {(!isLoading && !error) ? (
              <ul className="match-list">
                {(tournamentMatches?.length > 0 ? tournamentMatches.map((item) => {
                  const { matches } = item;
                  return matches?.length > 0 ? matches.map((m) => <SportItem event={m} key={m.id} isMobile={isMobile} redirect="/dashboard/matches" />) : null;
                }) : <Error text={t(msg154)} />)}
              </ul>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

UniversalComponent.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default UniversalComponent;
