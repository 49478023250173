import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { authParams, getBase64Hash } from 'components/helpers/base64AuthParams';
import tokenText from 'components/helpers/tokenText';
import translationKeys from 'translations/translationKeys';
import axios from 'axios';
import ERROR_MESSAGES from 'utils/error-code';
import { getUserInfo, setUserInfo } from 'components/helpers/checkToken';
import getLanguage from 'components/helpers/getLanguage';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});

const { msg29 } = translationKeys;

export const resetPin = createAsyncThunk(
  'sessions/resetPin',
  async (obj, thunkAPI) => {
    const tempUserInfo = getUserInfo(true);
    const lang = getLanguage();
    const requestParams = `phone=${(obj.phone && obj.phone) || tempUserInfo?.phone}&pin=${obj.pin}&newPin=${obj.newPin}&matricule=${(obj.matricule && obj.matricule) || tempUserInfo?.agentId}&channel=${obj.channel}&str_From=${(obj.strFrom && obj.strFrom) || tempUserInfo?.phone}&ime=${obj.ime}&lang=${lang}`;
    const api = `/agent-servicev2/ser_change_pin?${requestParams}`;
    const url = `${apiBaseUrl}/${apiVersion2}${api}`;
    const signatureParam = `/agent-servicev2/ser_change_pin${requestParams}`;
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}${JSON.stringify({})}`)}`,
        },
      };
      const resp = await client.post(url, {}, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        message: error.response.data.message || 'An error occurred',
        erc: error.response.data.erc,
      });
    }
  },
);

const initialState = {
  resetPinData: null,
  showResetModal: false,
  resetPinForm: false,
  resetLoading: false,
  resetSuccessMsg: '',
  resetMessage: '',
};

const resetPinSlice = createSlice({
  name: 'resetPIn',
  initialState,
  reducers: {
    showResetPinForm: (state) => ({
      ...state,
      resetPinForm: true,
    }),
    hideResetPinForm: (state) => ({
      ...state,
      resetPinForm: false,
    }),
    hideResetMsg: (state) => ({
      ...state,
      resetMessage: '',
      resetSuccessMsg: '',
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetPin.pending, (state) => ({
        ...state,
        resetLoading: true,
        resetMessage: '',
        resetPinForm: false,
      }))
      .addCase(resetPin.fulfilled, (state, { payload }) => {
        const { erc } = payload;
        let resetMessage = '';
        let resetSuccessMsg = '';

        if (Number(erc) === 25) {
          resetSuccessMsg = ERROR_MESSAGES[erc];
          const userInfo = getUserInfo();
          if (Object.keys(userInfo).length > 0) {
            userInfo.agentPwd = payload.pwd;
            setUserInfo(userInfo);
          }
        } else {
          resetMessage = ERROR_MESSAGES[erc] ?? msg29;
        }

        return {
          ...state,
          resetMessage,
          resetSuccessMsg,
          resetLoading: false,
          resetPinForm: false,
        };
      })

      .addCase(resetPin.rejected, (state, { payload }) => {
        let resetMessage;
        if (payload?.erc === '001') {
          resetMessage = tokenText;
        } else { resetMessage = msg29; }
        return {
          ...state,
          resetLoading: false,
          resetMessage,
          resetSuccessMsg: '',
        };
      });
  },
});

export const { showResetPinForm, hideResetPinForm, hideResetMsg } = resetPinSlice.actions;
export default resetPinSlice.reducer;
