import React, { useState, useEffect } from 'react';
import '../../../../styles/_home-detail.scss';
import { verifyTicket } from 'redux/verify-ticket/verifyTicketSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import translationKeys from 'translations/translationKeys';
import PropTypes from 'prop-types';
import isNativeApp from 'components/helpers/native';
import { scan } from 'components/helpers/navtiveFunction';
import ticketwhite from '../../../../assets/images/mathys/svg/ticketwhite.svg';
import barCodeImage from '../../../../assets/images/mathys/svg/barcode.svg';

const VerifyTicket = ({ isMobile }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { agentId } = useSelector((store) => store.login);
  const [disabled, setDisabled] = useState(true);
  const [ticketRef, setTicketRef] = useState('');
  const { ticketPaid } = useSelector((store) => store.ticketPaid);
  const { ticketCanceled } = useSelector((store) => store.ticketCanceled);
  const { msg135, msg18, msg71 } = translationKeys;

  const handleChange = () => {
    const ticketNumber = document.getElementById('ticketNumber').value;
    setTicketRef(ticketNumber);
    if (ticketNumber !== '') {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const scanningTicket = (e) => {
    e.preventDefault();
    scan();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const ticketNumber = ticketRef.split('#')[0];
    localStorage.setItem('ticketNumber', ticketNumber);
    localStorage.setItem('payRefCode', `%5B%22${ticketNumber}%22%5D`);
    dispatch(verifyTicket({
      refCode: ticketNumber,
      agentId,
    }));
  };

  const setTicketValue = (ticketNumber) => {
    setTicketRef(ticketNumber);
    setDisabled(false);
  };

  useEffect(() => {
    if (ticketPaid || ticketCanceled) {
      setDisabled(true);
      setTicketRef('');
      document.getElementById('ticket-verification-form').reset();
    }
  }, [ticketPaid, ticketCanceled, dispatch]);

  // Expose the function globally
  useEffect(() => {
    window.onQRCodeCallback = (ticketNumber) => {
      if (ticketNumber !== 'null' || ticketNumber !== null) {
        setTicketValue(ticketNumber);
      }
    };
    return () => {
      delete window.onQRCodeCallback;
    };
  }, []);

  return (
    <div className="details verifier-ticket">
      {!isMobile && (
      <div className="header">
        <img src={ticketwhite} alt="" />
        <span>{t(msg135)}</span>
      </div>
      )}
      <div className="content verify-content">
        <form action="" onSubmit={handleSubmit} className="verify-form" id="ticket-verification-form">
          <div className="content-grid">
            <div className="input-wrap">
              <label htmlFor="ticketNumber" className="form-label verify-label">
                {t(msg71)}
              </label>
              <input
                type="text"
                className="form-control verify-input"
                id="ticketNumber"
                value={ticketRef}
                required
                placeholder="Ex: 300614792988"
                onChange={handleChange}
              />
            </div>
            {isNativeApp() ? (
              <div className="actions reverse no-padding">
                <button
                  type="submit"
                  className="btn btn-secondary verify-btn"
                  disabled={disabled}
                >
                  <span>{t(msg18)}</span>
                </button>
                <button type="button" className="btn btn-icon" onClick={scanningTicket}>
                  <span className="icon" style={{ maskImage: `url(${barCodeImage})` }} />
                </button>
              </div>
            ) : (
              <button
                type="submit"
                className="btn btn-secondary verify-btn"
                disabled={disabled}
              >
                <span>{t(msg18)}</span>
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

VerifyTicket.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default VerifyTicket;
