/* eslint  max-len: 0 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { authParams, getBase64Hash } from 'components/helpers/base64AuthParams';
import { userObj } from 'components/common/requestBody';
import getLanguage from 'components/helpers/getLanguage';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});

export const getClientBonus = createAsyncThunk(
  'client/bonus',
  async (agentId, thunkAPI) => {
    const token = JSON.parse(sessionStorage.getItem('token'));
    const lang = getLanguage();
    const requestParams = `lang=${lang}&id=${agentId}&channel_id=${userObj.chl}`;
    const api = `/user/bonus-balance?${requestParams}`;
    const url = `${apiBaseUrl}/${apiVersion2}${api}`;
    const signatureParam = `/user/bonus-balance${requestParams}`;
    try {
      const headers = {
        'Content-Type': 'application/json; charset=UTF-8',
        'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}`)}`,
        Authorization: token ? `Bearer ${token}` : '',
      };
      const resp = await client.get(url, { headers });
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        message: error?.response?.data?.message || 'An error occurred',
        erc: error?.response?.data?.erc || 0,
      });
    }
  },
);

const initialState = {
  agentBonuses: [],
  agentBonusLoading: false,
  agentBonusError: '',
  agentBonusSuccess: false,
  agentHasBonus: false,
};

const clientBonusSlice = createSlice({
  name: 'agent-bonus',
  initialState,
  reducers: {
    resetBonuses: (state) => ({
      ...state,
      ...initialState,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClientBonus.pending, (state) => ({
        ...state,
        bonusLoading: true,
      }))
      .addCase(getClientBonus.fulfilled, (state, { payload }) => {
        let agentBonusSuccess = false;
        let agentBonuses = [];
        let agentHasBonus = false;

        if (payload?.bon) {
          agentBonusSuccess = true;
          agentBonuses = payload.bon.map(((b) => ({
            name: b.name,
            amount: Number(b.amt),
            value: b.bonusID,
            partialStake: b?.partialStake ?? false,
            bonusAccumulator: b?.bonusAccumulator ?? false,
            stakeDeduction: b?.stakeDeduction ?? false,
          })));

          agentHasBonus = agentBonuses.some((agentBonus) => agentBonus.amount > 0);
        }

        return {
          ...state,
          agentBonusLoading: false,
          agentBonuses,
          agentHasBonus,
          agentBonusSuccess,
        };
      })

      .addCase(getClientBonus.rejected, (state) => ({
        ...state,
        agentBonusLoading: false,
        agentBonusSuccess: false,
        agentHasBonus: false,
        agentBonusError: '',
        agentBonuses: [],
      }));
  },
});

export const { resetBonuses } = clientBonusSlice.actions;

export default clientBonusSlice.reducer;
