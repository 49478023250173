/* eslint  prefer-destructuring: 0 */
/* eslint  max-len: 0 */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../../../../styles/_home-detail.scss';
import { v4 as uuidv4 } from 'uuid';
import { getHistory, resetHistoryLoaded } from 'redux/history/historySlice';
import translationKeys from 'translations/translationKeys';
import { formatCurrency } from 'components/helpers/currencyOptions';
import PropTypes from 'prop-types';
import { navigatorLang } from 'components/helpers/getLanguage';
import clock from '../../../../assets/images/mathys/svg/clock.svg';

const History = ({ isMobile }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const { pathname } = location;
  const { history } = useSelector((store) => store.history);
  const { currencyConfigs } = useSelector((store) => store.currencyConfigs);
  const { agentId } = useSelector((store) => store.login);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const {
    msg17, msg19, msg57, msg58, msg59, msg60, msg61, msg62, msg67,
    msg18, msg68, msg54, msg64, msg65, msg66, msg86, msg87, msg88,
    msg121, msg178, msg195, msg199,
  } = translationKeys;
  const buttonsText = [
    {
      text: t(msg57),
      query: 'BET',
    },
    {
      text: t(msg58),
      query: 'CANCEL',
    },
    { text: t(msg59), query: 'WINING_TICKET' },
    { text: t(msg60), query: 'DIRECT_RECHARGE' },
    { text: t(msg19), query: 'WITHDRAW' },
    { text: t(msg61), query: 'COUPON_RECHARGE' },
  ];
  const handleSubmit = (e) => {
    e.preventDefault();
    const startDateFormat = new Date(startDate);
    startDateFormat.setHours(0, 0, 0, 0);
    const startUnixTimestamp = Math.floor(startDateFormat.getTime() / 1000);
    const endDateFormat = new Date(endDate);
    endDateFormat.setHours(23, 59, 59, 999);
    const endUnixTimestamp = Math.floor(endDateFormat.getTime() / 1000);
    dispatch(getHistory({
      startDate: startUnixTimestamp,
      endDate: endUnixTimestamp,
      agentId,
      type: '',
    }));
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name === 'startDate') {
      setStartDate(value);
    } else if (name === 'endDate') {
      setEndDate(value);
    }
  };

  const handleButtonClick = (query) => {
    const startDateFormat = new Date(startDate);
    startDateFormat.setHours(0, 0, 0, 0);
    const startUnixTimestamp = Math.floor(startDateFormat.getTime() / 1000);
    const endDateFormat = new Date(endDate);
    endDateFormat.setHours(23, 59, 59, 999);
    const endUnixTimestamp = Math.floor(endDateFormat.getTime() / 1000);
    dispatch(getHistory({
      startDate: startUnixTimestamp,
      endDate: endUnixTimestamp,
      agentId,
      type: query,
    }));
  };

  const formatData = (item) => {
    const timestamp = item.dtCREATED;
    const date = new Date(timestamp);

    let refCode;

    if ((/"([^"]*)"/.test(item.str_REF_CODE)) || (/'([^']*)'/.test(item.str_REF_CODE))) {
      const array = JSON.parse(item.str_REF_CODE.replace(/'/g, '"')); // Replace single quotes with double quotes and parse as JSON
      refCode = array.join(', ');
    } else {
      refCode = item.str_REF_CODE;
    }

    let type;

    switch (item.str_type) {
      case 'CANCEL':
        type = msg87;
        break;
      case 'WINNING_TICKET':
        type = msg88;
        break;
      case 'DIRECT_RECHARGE':
        type = msg121;
        break;
      case 'WITHDRAW':
        type = msg19;
        break;
      case 'COUPON_RECHARGE':
        type = msg61;
        break;
      default:
        type = msg86;
        break;
    }

    const options = {
      year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric', second: 'numeric', hour24: true,
    };
    const formattedDate = date.toLocaleString('fr-CG', options); // Adjust locale as needed

    return {
      transId: item.lgAgentTransactionID, ref: refCode, type, amount: formatCurrency(Math.abs(item.intAMOUNT), currencyConfigs), date: formattedDate,
    };
  };

  useEffect(() => {
    dispatch(resetHistoryLoaded());
  }, [pathname, dispatch]);
  const isDateFieldEmpty = () => (startDate === '' || endDate === '');

  return (
    <div className="details history">
      {!isMobile && (
      <div className="header">
        <img src={clock} alt="" />
        <span>{t(msg17)}</span>
      </div>
      )}
      <div className="content">
        <form action="" onSubmit={handleSubmit} className={isMobile ? '' : 'row-form'}>
          <div className="input-wrap">
            <label htmlFor="startDate" className="form-label label">
              {t(msg62)}
            </label>
            <input
              type="date"
              className={`form-control${startDate !== '' ? ' has-value' : ''}`}
              id="startDate"
              name="startDate"
              placeholder={navigatorLang() === 'fr' ? t(msg195) : t(msg199)}
              onChange={handleChange}
              required
            />
          </div>
          <div className="input-wrap">
            <label htmlFor="endDate" className="form-label label">
              {t(msg68)}
            </label>
            <input
              type="date"
              className={`form-control${endDate !== '' ? ' has-value' : ''}`}
              id="endDate"
              name="endDate"
              placeholder={navigatorLang() === 'fr' ? t(msg195) : t(msg199)}
              onChange={handleChange}
              required
            />
          </div>
          <button
            type="submit"
            className="btn btn-secondary"
            disabled={isDateFieldEmpty()}
          >
            <span>{t(msg18)}</span>
          </button>
        </form>
        <div className="table-content">
          <div className="statut-filter-control">
            {!isMobile ? buttonsText.map((item) => item && (
            <button
              key={uuidv4()}
              type="button"
              className="btn btn-outline-secondary text-capitalize"
              onClick={() => handleButtonClick(item.query)}
              disabled={isDateFieldEmpty()}
            >
              {t(item.text)}
            </button>
            )) : (
              <select defaultValue="" onChange={(e) => handleButtonClick(e.target.value)} className="form-select control" disabled={isDateFieldEmpty()}>
                <option value="">{t(msg178)}</option>
                {buttonsText.map((item) => item && (
                <option key={item.text} value={item.query}>
                  {t(item.text)}
                </option>
                ))}
              </select>
            )}
          </div>
          {(history?.length > 0) ? (
            <div className="history-data">
              {!isMobile && (
              <table className="table">
                <thead className="table-light">
                  <tr className="table-dark">
                    <th scope="col" className="text-light">{t('Date')}</th>
                    <th scope="col">{t(msg64)}</th>
                    <th scope="col">{t(msg54)}</th>
                    <th scope="col">{t(msg65)}</th>
                    <th scope="col">{t(msg66)}</th>
                  </tr>
                </thead>
                <tbody>
                  {history.map((item) => {
                    const formattedItem = formatData(item);
                    return (
                      <tr key={uuidv4()}>
                        <td>{formattedItem.date}</td>
                        <td>{t(formattedItem.type)}</td>
                        <td>{formattedItem.amount}</td>
                        <td>{formattedItem.ref}</td>
                        <td>{formattedItem.transId}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              )}
              {isMobile && history.map((item) => {
                const formattedItem = formatData(item);
                return (
                  <div className="history-data-row" key={formattedItem.transId}>
                    <div className="column">
                      <span className="time">{formattedItem.date}</span>
                      <span className="ref">{formattedItem.ref}</span>
                    </div>
                    <div className="column">
                      <span className="amount">{formattedItem.amount}</span>
                      <span className="ticket-type">{formattedItem.type}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : <div className="no-data">{t(msg67)}</div>}

        </div>
      </div>
    </div>
  );
};

History.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default History;
