/* eslint  max-len: 0 */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { hideModal } from 'redux/place-bet/resumeBetsSlice';
import translationKeys from 'translations/translationKeys';
import { clearBetSlip } from 'redux/configs/betConfigsSlice';
import { resetSuccess, placeBet } from 'redux/place-bet/placeBetsSlice';
import { hideVerifyModal } from 'redux/verify-ticket/verifyTicketSlice';
import { directRecharge, hideRechargeConfirmBox } from 'redux/deposit/depositSlice';
import { bonRecharge, hideBonRechargeConfirmBox } from 'redux/deposit/bonDepositSlice';
import {
  resetAccountCreationMessage, resetAccountCreation, resetAccountValidation, resetResendActivationCode,
} from 'redux/customer-account-management/customerAccountManagementSlice';
import { hideshowBalanceBox } from 'redux/solde/getBalanceSlice';
import { logout, resetUserInfo } from 'redux/login/loginSlice';
import { printing, printTicket, refreshPrinting } from 'redux/print/printTicketSlice';
import {
  cancelTicket, resetTicketCanceled, hideCancelConfirmBox, showCancelConfirmBox,
} from 'redux/verify-ticket/cancelTicketSlice';
import { hideWithdrawWithCodeBox, withdrawWithCode } from 'redux/withdrawal/withdrawWithCodeSlice';
import {
  payTicket, showPayConfirmBox, resetPayTicket, hidePayConfirmBox,
} from 'redux/verify-ticket/payTicketSlice';
import { hideCheckWithdrawConfirmBox } from 'redux/withdrawal/checkWithdrawalSlice';
import { hideHistModal } from 'redux/history/historySlice';
import { printTicketData } from 'components/helpers/navtiveFunction';
import { showResetPinForm, hideResetPinForm, hideResetMsg } from 'redux/reset-pin/resetPinSlice';
import { IoMdClose } from 'react-icons/io';
import tokenText from 'components/helpers/tokenText';
import isNativeApp from 'components/helpers/native';
import { formatCurrency } from 'components/helpers/currencyOptions';
import {
  cancelObj, payObj, checkWithdrawObj, userObj,
} from './requestBody';
import {
  VerifyTicketContent, ResumeBetContent, MessageContent, SoldeContent,
} from './ModalContents';
import '../../styles/_modal.scss';
import logo from '../../assets/images/mathys/png/logo.png';

const Modal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userAgent } = window.navigator;
  const couponAmt = JSON.parse(localStorage.getItem('couponAmt'));
  const refCode = localStorage.getItem('payRefCode');
  const ticketNumber = localStorage.getItem('ticketNumber');
  const [className, setClassName] = useState('modalContainer');
  const {
    betType, stake, pick, minStake, multipleSimple, simpleTickets, retail, agentBonus,
  } = useSelector((store) => store.betSlip);
  const {
    resumeBets, previewSuccess, previewMsg, resumeBetLoading,
  } = useSelector((store) => store.resumeBets);
  const { balance } = useSelector((store) => store.balance);
  const { agentId, agentPwd, agentPhone } = useSelector((store) => store.login);
  const {
    isCancelTicketLoading,
    ticketCanceled,
    cancelTicketMsg,
    failedCancelTicket,
    ticketNotRegistered,
    ticketAlreadyCanceled,
    showCancelConfirm,
  } = useSelector((store) => store.ticketCanceled);
  const { currencyConfigs } = useSelector((store) => store.currencyConfigs);
  const {
    ticketPaid, isPaidLoading, failedPayTicket, payTicketMsg, paidTicketPrintData, showPayConfirm,
  } = useSelector((store) => store.ticketPaid);
  const {
    printError, printSuccess, unPrintedTicket, printErrorMessage, isPrintLoading,
  } = useSelector((store) => store.printTicket);
  const {
    success, msg, isPlaceBetLoading, placeBets, placedBetTicketNumber, failedPlacedBet,
  } = useSelector((store) => store.placeBets);
  const { successGetBalance, isbalanceLoading, errorGetBalance } = useSelector((store) => store.balance);
  const {
    showBonRechargeConfirm, bonRechargeSuccess, errorBonRecharge, isBonRechargeLoading, bonRechargeData,
  } = useSelector((store) => store.bonRechargeData);
  const { checkWithdrawLoading } = useSelector((store) => store.withdrawalChecked);
  const {
    successfetchTicket, ticketData, canCancel, canPay, verifyTicketMsg,
    isTicketLoading,
  } = useSelector((store) => store.ticketData);

  const {
    rechargeValue, showRechargeConfirm, errorRecharge,
    isdepositLoading, rechargeSuccess, depositData,
  } = useSelector((store) => store.depositData);
  const { agentCode } = useSelector((store) => store.voucherProviders);
  const {
    checkMsg, canWithdraw, withDrawalAmount, canNotWithdraw, failedCheckWithdraw, cac, wcd,
  } = useSelector((store) => store.withdrawalChecked);
  const {
    withdrawWithCodeLoading, withdrawWithCodeData, canPrint, failedwithdrawWithCode, withdrawWithCodeMsg,
  } = useSelector((store) => store.withdrawWithCode);
  const { loadingHistory, histMsg } = useSelector((store) => store.history);
  const { resetLoading, resetMessage, resetSuccessMsg } = useSelector((store) => store.resetPinData);
  const {
    accountCreation, accountValidation, resendActivationCode,
  } = useSelector((store) => store.customerAccount);

  let ticketValue;
  if (betType === 'SIMPLE') {
    ticketValue = 1;
  } else if (betType === 'MULTIPLE') {
    ticketValue = 2;
  } else if (betType === 'MULTI_SIMPLE') {
    ticketValue = 5;
  } else {
    ticketValue = 3;
  }

  let amt = (Number(stake) === 0 ? Number(minStake) : Number(stake));

  let retailBonusParams = {};

  if (agentBonus?.value) {
    retailBonusParams = { bonid: agentBonus.value };
  } else if (Object.keys(retail).length > 0) {
    retailBonusParams = retail;
  }

  if (multipleSimple) {
    amt = [...simpleTickets].map((simpleTicket) => (Number(simpleTicket.stake) === 0 ? Number(minStake) : Number(simpleTicket.stake))).join('S');
  }

  const betObjList = [{
    uid: agentId,
    pwd: agentPwd,
    phn: agentPhone,
    pick,
    amt,
    chl: userObj.chl,
    tkt: ticketValue,
    ref: new Date().getTime(),
    dvr: 0.1,
    imei: userObj.imei,
    dtp: userObj.DT,
    kid: 1,
    vers: userObj.ver,
    ...retailBonusParams,
  }];

  const couponObj = {
    agentId,
    agentPwd,
    agentPhone,
    iref: new Date().getTime(),
    ref: `2*${couponAmt}`,
    internalRef: new Date().getTime(),
    reference: `${agentCode}*${couponAmt}*${agentPhone}`,
    imei: userObj.imei,
    chl: userObj.chl,
    ver: userObj.ver,
    deviceType: userObj.DT,
  };
  const directRechargeObj = {
    agentId,
    agentPwd,
    agentPhone,
    chl: userObj.chl,
    imei: userObj.imei,
    ver: userObj.ver,
    acc: rechargeValue.acc,
    amt: rechargeValue.amt,
  };
  const {
    msg43, msg44, msg45, msg46, msg47, msg48, msg1, msg50,
    msg52, msg53, msg54, msg49, msg51, msg60, msg196, msg197,
    msg198, msg215, msg216, msg221, msg222, msg223, msg226, msg230,
  } = translationKeys;

  const successMessage = () => (success && !printError && !isPrintLoading) || (accountValidation.success && printSuccess && !isPrintLoading) || (printSuccess && !printError && !isPrintLoading);

  let content = '';
  if (failedCancelTicket) {
    content = <MessageContent text={t(cancelTicketMsg)} color="red" />;
  }
  if (ticketNotRegistered) {
    content = <MessageContent text={t(cancelTicketMsg)} color="red" />;
  }
  if (ticketAlreadyCanceled) {
    content = <MessageContent text={t(cancelTicketMsg)} color="red" />;
  }
  if (ticketCanceled) {
    content = <MessageContent text={t(cancelTicketMsg)} color="green" />;
  }
  if (msg && msg.length > 0) {
    content = <MessageContent text={t(msg)} color="red" />;
  }
  if (previewMsg && previewMsg?.length > 0) {
    content = <MessageContent text={t(previewMsg)} color="red" />;
  }
  if (histMsg.length > 0) {
    content = <MessageContent text={t(histMsg)} color="red" />;
  }
  if (ticketPaid) {
    content = <MessageContent text={t(payTicketMsg)} color="green" />;
  }
  if (failedPayTicket) {
    content = <MessageContent text={t(payTicketMsg)} color="red" />;
  }
  if (failedPlacedBet && (cancelTicketMsg.length === 0) && msg.length === 0) {
    content = <MessageContent text={t(msg215)} color="red" />;
  }
  if (unPrintedTicket && !printError && !isPrintLoading) {
    content = <MessageContent text={t(msg216)} color="red" />;
  }
  if (showPayConfirm) {
    content = <MessageContent text={t(msg43)} color="red" />;
  }
  if (showCancelConfirm) {
    content = <MessageContent text={t(msg44)} color="red" />;
  }
  if (showRechargeConfirm) {
    content = <MessageContent text={t(msg230, { amount: formatCurrency(Number(rechargeValue.amt), currencyConfigs).toUpperCase() })} color="red" />;
  }
  if (showBonRechargeConfirm) {
    content = <MessageContent text={t(msg45, { amount: formatCurrency(Number(couponAmt), currencyConfigs).toUpperCase() })} color="red" />;
  }
  if (errorRecharge.length > 0) {
    content = <MessageContent text={t(errorRecharge)} color="red" />;
  }
  if (resetMessage && resetMessage?.length > 0) {
    content = <MessageContent text={t(resetMessage)} color="red" />;
  }
  if (resetSuccessMsg?.length > 0) {
    content = <MessageContent text={t(resetSuccessMsg)} color="green" />;
  }
  if (errorBonRecharge?.length > 0) {
    content = <MessageContent text={t(errorBonRecharge)} color="red" />;
  }
  if (successMessage()) {
    content = <MessageContent text={t(msg46)} color="green" />;
  }

  if (canWithdraw || canNotWithdraw || failedCheckWithdraw) {
    content = <MessageContent text={t(checkMsg, { amount: formatCurrency(Number(withDrawalAmount), currencyConfigs).toUpperCase() })} color="red" />;
  }
  if (canPrint) {
    content = <MessageContent text={t(msg47)} color="green" />;
  }

  if (failedwithdrawWithCode) {
    content = <MessageContent text={t(withdrawWithCodeMsg)} color="red" />;
  }
  if (verifyTicketMsg && verifyTicketMsg?.length > 0) {
    content = <MessageContent text={t(verifyTicketMsg)} color="red" />;
  }

  if (accountCreation.message.length > 0) {
    content = <MessageContent text={t(accountCreation.message)} color={accountCreation.success ? 'green' : 'red'} fontSize={accountCreation.success ? 14 : 16} />;
  }

  if (accountValidation.message.length > 0) {
    if (accountValidation.success && !printSuccess) {
      content = (
        <>
          <MessageContent text={t(accountValidation.message)} color="black" />
          <MessageContent text={t(msg196)} color="#777777" fontSize={12} />
        </>
      );
    }

    if (!accountValidation.success) {
      content = <MessageContent text={t(accountValidation.message)} color="red" />;
    }
  }

  if (resendActivationCode.message.length > 0) {
    content = <MessageContent text={t(resendActivationCode.message)} color={resendActivationCode.success ? 'green' : 'red'} />;
  }

  if (isPrintLoading) {
    content = <MessageContent text={t(msg226)} color="green" />;
  }

  if (isCancelTicketLoading
     || isPlaceBetLoading || loadingHistory || isPaidLoading || isdepositLoading || isBonRechargeLoading
     || withdrawWithCodeLoading || isTicketLoading || checkWithdrawLoading || isbalanceLoading
     || resetLoading || resumeBetLoading || accountCreation.isLoading || accountValidation.isLoading
  ) {
    content = <MessageContent text={t(msg48)} color="green" />;
  }
  if (successGetBalance) {
    content = <SoldeContent arr={balance} />;
  }
  if (previewSuccess) {
    content = <ResumeBetContent arr={resumeBets} />;
  }

  if (successfetchTicket) {
    content = <VerifyTicketContent arr={ticketData} />;
  }
  if (printError) {
    content = <MessageContent text={t(printErrorMessage)} color="red" />;
  }

  const iconStyle = {
    color: 'black',
    fontSize: '1.5rem',
  };

  const createTicket = useCallback((data, type) => {
    if (type === 'deposit') {
      return [
        '#_01_#',
        t(msg60).toUpperCase(),
        '--------------------------------',
        `${t(msg221).toUpperCase()}  : ${data.account}`,
        '--------------------------------',
        `${t(msg54).toUpperCase()}       : ${formatCurrency(Number(data.amount), currencyConfigs).toUpperCase()}`,
        '--------------------------------',
        `REF           : ${data.ref}`,
        '--------------------------------',
        `AGENT         : ${data.agent}`,
        `DATE          : ${data.date}`,
        '--------------------------------',
        '       Powered by FlexBet',
        '--------------------------------',
      ];
    }
    return [
      '#_01_#',
      t(msg222).toUpperCase(),
      '--------------------------------',
      `${t(msg223).toUpperCase()}  : ${data.code}`,
      '--------------------------------',
      `${t(msg54).toUpperCase()}       : ${formatCurrency(Number(data.amount), currencyConfigs).toUpperCase()}`,
      '--------------------------------',
      `REF           : ${data.ref}`,
      '--------------------------------',
      `AGENT         : ${data.agent}`,
      `DATE          : ${data.date}`,
      '--------------------------------',
      '       Powered by FlexBet',
      '--------------------------------',
    ];
  }, [currencyConfigs, t]);

  const timeout = 200;
  const closeModal = () => {
    setClassName('go-back-to-top');
    if (previewMsg && previewMsg.length > 0) {
      setTimeout(() => {
        dispatch(hideModal());
      }, timeout);
    }
    if (previewSuccess) {
      setTimeout(() => {
        dispatch(hideModal());
      }, timeout);
    }
    if (ticketAlreadyCanceled || ticketNotRegistered || ticketCanceled || failedCancelTicket) {
      setTimeout(() => {
        dispatch(resetTicketCanceled());
        if (failedPlacedBet) {
          localStorage.removeItem('placedBet');
          dispatch(resetSuccess());
        }
        dispatch(hideVerifyModal());
      }, timeout);
    }
    if (ticketPaid || failedPayTicket) {
      setTimeout(() => {
        dispatch(resetPayTicket());
        dispatch(hideVerifyModal());
      }, timeout);
    }
    if (msg && msg.length > 0) {
      setTimeout(() => {
        dispatch(hideModal());
        dispatch(resetSuccess());
      }, timeout);
    }

    if (accountCreation.message.length > 0) {
      setTimeout(() => {
        dispatch(resetAccountCreationMessage());
      }, timeout);
    }

    if (resendActivationCode.message.length > 0) {
      setTimeout(() => {
        dispatch(resetResendActivationCode());
      }, timeout);
    }

    if (accountValidation.message.length > 0) {
      setTimeout(() => {
        dispatch(resetAccountValidation());
        if (accountValidation.success) {
          dispatch(resetAccountCreation());
          if (printSuccess) {
            dispatch(refreshPrinting());
          }
          navigate('/dashboard/account-creation', { replace: true });
        }
      }, timeout);
    }

    if (printSuccess || printError) {
      setTimeout(() => {
        dispatch(refreshPrinting());
        if (success) {
          dispatch(clearBetSlip());
          dispatch(resetSuccess());
        }
      }, timeout);
    }

    if (successfetchTicket) {
      setTimeout(() => {
        dispatch(hideVerifyModal());
      }, timeout);
    }
    if (verifyTicketMsg) {
      setTimeout(() => {
        dispatch(hideVerifyModal());
      }, timeout);
    }
    if (showPayConfirm) {
      setTimeout(() => {
        dispatch(hidePayConfirmBox());
      }, timeout);
    }
    if (showCancelConfirm) {
      setTimeout(() => {
        dispatch(hideCancelConfirmBox());
      }, timeout);
    }
    if (showRechargeConfirm && errorRecharge.length === 0) {
      setTimeout(() => {
        dispatch(hideRechargeConfirmBox());
      }, timeout);
    }
    if (errorRecharge.length > 0) {
      setTimeout(() => {
        dispatch(hideRechargeConfirmBox());
      }, timeout);
    }
    if (showBonRechargeConfirm && errorBonRecharge.length === 0) {
      setTimeout(() => {
        dispatch(hideBonRechargeConfirmBox());
      }, timeout);
    }
    if (errorBonRecharge.length > 0) {
      setTimeout(() => {
        dispatch(hideBonRechargeConfirmBox());
      }, timeout);
    }
    if (errorGetBalance.length > 0) {
      setTimeout(() => {
        dispatch(hideshowBalanceBox());
      }, timeout);
    }
    if (successGetBalance) {
      setTimeout(() => {
        dispatch(hideshowBalanceBox());
      }, timeout);
    }

    if (resetSuccessMsg.length > 0) {
      dispatch(hideResetMsg());
      dispatch(hideResetPinForm());
      dispatch(resetUserInfo());
    }

    if (rechargeSuccess) {
      setTimeout(() => {
        dispatch(hideRechargeConfirmBox());
      }, timeout);
    }

    if (bonRechargeSuccess) {
      setTimeout(() => {
        dispatch(hideBonRechargeConfirmBox());
      }, timeout);
    }

    if (canPrint || failedwithdrawWithCode) {
      setTimeout(() => {
        dispatch(hideCheckWithdrawConfirmBox());
        dispatch(hideWithdrawWithCodeBox());
      }, timeout);
    }

    if (canWithdraw) {
      setTimeout(() => {
        dispatch(hideCheckWithdrawConfirmBox());
      }, timeout);
    }
    if (canNotWithdraw || failedCheckWithdraw) {
      setTimeout(() => {
        dispatch(hideCheckWithdrawConfirmBox());
      }, timeout);
    }

    if (histMsg && histMsg.length > 0) {
      setTimeout(() => {
        dispatch(hideHistModal());
      }, timeout);
    }
    if (resetMessage && resetMessage.length > 0) {
      setTimeout(() => {
        dispatch(hideResetMsg());
        dispatch(showResetPinForm());
      }, timeout);
    }
  };

  const printAccountQRCode = () => {
    const { phoneNumber, accountCode } = accountValidation;

    const data = [
      '#_01_#',
      '--------------------------------',
      `${t(msg197)}: ${phoneNumber}`,
      `${t(msg198)}: ${accountCode}`,
      '--------------------------------',
      '#_03_#',
      '--------------------------------',
      '       Powered by FlexBet',
      '--------------------------------',
    ];

    // Save ticket info
    localStorage.setItem('currentTicket', JSON.stringify({ ref: accountCode, data, status: 'TO_PRINT' }));

    if (isNativeApp()) {
      const QRCodeLines = {
        data,
        ref: accountCode,
      };
      dispatch(printing());
      printTicketData(QRCodeLines);
    } else {
      data.splice(6, 0, accountCode);
      dispatch(printTicket(data));
    }
  };

  const userLanguage = navigator.language.split('-')[0];

  useEffect(() => {
    if (success) {
      // Save ticket info
      localStorage.setItem('currentTicket', JSON.stringify({ ref: placedBetTicketNumber, data: placeBets[0], status: 'TO_PRINT' }));

      if (isNativeApp()) {
        placeBets.forEach((placedBet) => {
          dispatch(printing());
          printTicketData({ data: placedBet, ref: placedBetTicketNumber });
        });
      } else {
        placeBets.forEach((placedBet) => {
          dispatch(printTicket(placedBet));
        });
      }
    }
  }, [success, placeBets, userAgent, placedBetTicketNumber, dispatch]);

  useEffect(() => {
    if (ticketPaid && !printError) {
      const ticketRef = localStorage.getItem('ticketNumber', ticketNumber);
      // Save ticket info
      localStorage.setItem('currentTicket', JSON.stringify({ ref: ticketRef, data: paidTicketPrintData, status: 'TO_PRINT' }));
      setTimeout(() => {
        if (isNativeApp()) {
          dispatch(printing());
          printTicketData({ data: paidTicketPrintData, ref: ticketRef });
        } else {
          dispatch(printTicket(paidTicketPrintData));
        }
      }, timeout * 1.5);
    }
  }, [ticketPaid, paidTicketPrintData, printError, userAgent, ticketNumber, dispatch]);

  useEffect(() => {
    if (bonRechargeSuccess && !printError) {
      // Save ticket info
      localStorage.setItem('currentTicket', JSON.stringify({ ref: '', data: createTicket(bonRechargeData, 'recharge'), status: 'TO_PRINT' }));
      if (isNativeApp()) {
        dispatch(printing());
        printTicketData({ data: createTicket(bonRechargeData, 'recharge'), ref: '' });
      } else {
        dispatch(printTicket(createTicket(bonRechargeData, 'recharge')));
      }
    }
  }, [bonRechargeSuccess, printError, bonRechargeData, createTicket, dispatch]);

  useEffect(() => {
    if (rechargeSuccess && !printError) {
      // Save ticket info
      localStorage.setItem('currentTicket', JSON.stringify({ ref: '', data: createTicket(depositData, 'deposit'), status: 'TO_PRINT' }));
      if (isNativeApp()) {
        dispatch(printing());
        printTicketData({ data: createTicket(depositData, 'deposit'), ref: '' });
      } else {
        dispatch(printTicket(createTicket(depositData, 'deposit')));
      }
    }
  }, [rechargeSuccess, printError, depositData, createTicket, dispatch]);

  useEffect(() => {
    if (canPrint && !printError) {
      dispatch(hideCheckWithdrawConfirmBox());
      dispatch(hideWithdrawWithCodeBox());
      localStorage.setItem('currentTicket', JSON.stringify({ ref: '', data: withdrawWithCodeData, status: 'TO_PRINT' }));
      if (isNativeApp()) {
        dispatch(printing());
        printTicketData({ data: withdrawWithCodeData, ref: '' });
      } else {
        dispatch(printTicket(withdrawWithCodeData));
      }
    }
  }, [canPrint, printError, withdrawWithCodeData, dispatch]);
  return (
    <div className={className}>
      <div className="container">
        <div className="row">
          <div
            id="modal"
            className="text-center modal-info"
          >
            <div className="tck-prev">
              <div className="ticket">
                <div className="img-set">
                  <img src={logo} alt="mathysbet logo" />
                </div>
                <div className="modal-content">
                  {content}
                </div>
              </div>
              <article className="actions text-center">

                {previewSuccess && (
                  <>
                    <button
                      type="button"
                      className="btn btnClose"
                      onClick={() => {
                        closeModal();
                      }}
                    >
                      <IoMdClose style={iconStyle} />
                    </button>
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={() => {
                        // save bet to place before its validation
                        localStorage.setItem('placedBet', JSON.stringify({ data: betObjList, status: 'initiated' }));

                        dispatch(placeBet(betObjList));
                        dispatch(hideModal());
                      }}
                    >
                      {t(msg49)}
                    </button>
                  </>
                )}
                {previewMsg && previewMsg.length > 0 && (
                  <button
                    type="button"
                    className="btn btnClose"
                    onClick={() => {
                      closeModal();
                      if (previewMsg === tokenText) {
                        dispatch(logout());
                        localStorage.setItem('lang', userLanguage);
                      }
                    }}
                  >
                    <IoMdClose style={iconStyle} />
                  </button>
                )}
                {(ticketAlreadyCanceled || ticketNotRegistered || ticketCanceled || failedCancelTicket) && (
                  <button
                    type="button"
                    className="btn btnClose"
                    onClick={() => {
                      closeModal();
                      if (cancelTicketMsg === tokenText) {
                        dispatch(logout());
                        localStorage.setItem('lang', userLanguage);
                      }
                    }}
                  >
                    <IoMdClose style={iconStyle} />
                  </button>

                )}
                {(failedPayTicket) && (!printError || printSuccess) && (
                <button
                  type="button"
                  className="btn btnClose"
                  onClick={() => {
                    closeModal();
                    if (payTicketMsg === tokenText) {
                      dispatch(logout());
                      localStorage.setItem('lang', userLanguage);
                    }
                  }}
                >
                  <IoMdClose style={iconStyle} />
                </button>
                )}
                {msg && msg.length > 0 && !showCancelConfirm && (
                <button
                  type="button"
                  className="btn btnClose"
                  onClick={() => {
                    closeModal();
                    if (msg === tokenText) {
                      dispatch(logout());
                      localStorage.setItem('lang', userLanguage);
                    }
                  }}
                >
                  <IoMdClose style={iconStyle} />
                </button>
                ) }
                {(successMessage()) && (
                <button
                  type="button"
                  className="btn btnClose"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  <IoMdClose style={iconStyle} />
                </button>
                )}
                {successfetchTicket && canPay && (
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => {
                    dispatch(hideVerifyModal());
                    dispatch(showPayConfirmBox());
                  }}
                >
                  {t(msg50)}
                </button>
                )}
                {((successfetchTicket && canCancel)
                || (failedPlacedBet && !showCancelConfirm && (!isCancelTicketLoading && cancelTicketMsg.length === 0) && msg.length === 0)) && (
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    dispatch(hideVerifyModal());
                    dispatch(showCancelConfirmBox());
                  }}
                >
                  {t(msg51)}
                </button>
                )}
                {successfetchTicket && (
                <button
                  type="button"
                  className="btn btnClose"
                  onClick={() => closeModal()}
                >
                  <IoMdClose style={iconStyle} />
                </button>
                )}
                {verifyTicketMsg && (
                <button
                  type="button"
                  className="btn btnClose"
                  onClick={() => {
                    closeModal();
                    if (verifyTicketMsg === tokenText) {
                      dispatch(logout());
                      localStorage.setItem('lang', userLanguage);
                    }
                  }}
                >
                  <IoMdClose style={iconStyle} />
                </button>
                )}

                {showPayConfirm && !isPaidLoading && (
                <>
                  <button
                    type="button"
                    className="btn btnClose"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    <IoMdClose style={iconStyle} />
                  </button>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => {
                      dispatch(payTicket({
                        ...payObj,
                        refCode,
                        agentId,
                        agentPwd,
                        agentPhone,
                      }));
                    }}
                  >
                    {t('Oui')}
                  </button>
                </>
                )}

                {showCancelConfirm && (
                <>
                  {!failedPlacedBet && (
                  <button
                    type="button"
                    className="btn btnClose"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    <IoMdClose style={iconStyle} />
                  </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => {
                      let bid = ticketNumber;
                      if (failedPlacedBet) {
                        const failedBet = JSON.parse(localStorage.getItem('placedBet'));
                        bid = failedBet.data[0].ref;
                      }
                      dispatch(cancelTicket({
                        ...cancelObj,
                        agentId,
                        agentPwd,
                        agentPhone,
                        dtp: userObj.DT,
                        bid,
                      }));
                      dispatch(hideCancelConfirmBox());
                    }}
                  >
                    {t(msg52)}
                  </button>
                </>
                )}

                {showRechargeConfirm && errorRecharge.length === 0 && (
                <>
                  <button
                    type="button"
                    className="btn btnClose"
                    onClick={() => {
                      closeModal();
                    }}
                    disabled={isdepositLoading}
                  >
                    <IoMdClose style={iconStyle} />
                  </button>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => {
                      dispatch(hideRechargeConfirmBox());
                      dispatch(directRecharge(directRechargeObj));
                    }}
                    disabled={isdepositLoading}
                  >
                    {t(msg52)}
                  </button>
                </>
                )}

                {(errorRecharge.length > 0 || printError || failedwithdrawWithCode) && (
                <button
                  type="button"
                  className="btn btnClose"
                  onClick={() => {
                    closeModal();
                    if (errorRecharge === tokenText) {
                      dispatch(logout());
                      localStorage.setItem('lang', userLanguage);
                    }
                  }}
                >
                  <IoMdClose style={iconStyle} />
                </button>
                )}
                {showBonRechargeConfirm && errorBonRecharge.length === 0 && (
                <>
                  <button
                    type="button"
                    className="btn btnClose"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    <IoMdClose style={iconStyle} />
                  </button>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => {
                      dispatch(hideBonRechargeConfirmBox());
                      dispatch(bonRecharge(couponObj));
                    }}
                  >
                    {t(msg52)}
                  </button>
                </>
                )}

                {(accountCreation.message.length > 0 || resendActivationCode.message.length > 0) && (
                  <button
                    type="button"
                    className="btn btnClose"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    <IoMdClose style={iconStyle} />
                  </button>
                )}

                {accountValidation.message.length > 0
                  && accountValidation.success && !printSuccess && !printError && !isPrintLoading
                    && (
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => {
                          printAccountQRCode();
                        }}
                      >
                        {t(msg53)}
                      </button>
                    )}

                {accountValidation.message.length > 0 && !accountValidation.success && (
                <button
                  type="button"
                  className="btn btnClose"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  <IoMdClose style={iconStyle} />
                </button>
                )}

                {errorBonRecharge.length > 0 && (
                <button
                  type="button"
                  className="btn btnClose"
                  onClick={() => {
                    closeModal();
                    if (errorBonRecharge === tokenText) {
                      dispatch(logout());
                      localStorage.setItem('lang', userLanguage);
                    }
                  }}
                >
                  <IoMdClose style={iconStyle} />
                </button>
                )}
                { errorGetBalance.length > 0 && (
                <button
                  type="button"
                  className="btn btnClose"
                  onClick={() => {
                    closeModal();
                    if (errorGetBalance === tokenText) {
                      dispatch(logout());
                      localStorage.setItem('lang', userLanguage);
                    }
                  }}
                >
                  <IoMdClose style={iconStyle} />
                </button>
                )}
                {successGetBalance && !printError && (
                <>
                  <button
                    type="button"
                    className="btn btnClose"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    <IoMdClose style={iconStyle} />
                  </button>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => {
                      dispatch(hideshowBalanceBox());
                      // Save ticket info
                      localStorage.setItem('currentTicket', JSON.stringify({ ref: '', data: balance, status: 'TO_PRINT' }));
                      if (isNativeApp()) {
                        dispatch(printing());
                        printTicketData({ data: balance, ref: '' });
                      } else {
                        dispatch(printTicket(balance));
                      }
                    }}
                  >
                    {t(msg53)}
                  </button>
                </>
                )}
                {canWithdraw && (
                <>
                  <button
                    type="button"
                    className="btn btnClose"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    <IoMdClose style={iconStyle} />
                  </button>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => {
                      dispatch(hideCheckWithdrawConfirmBox());
                      dispatch(withdrawWithCode({
                        ...checkWithdrawObj,
                        wcd,
                        cac,
                        agentId,
                        agentPwd,
                        agentPhone,
                      }));
                    }}
                  >
                    {t(msg50)}
                  </button>
                </>
                )}
                {(canNotWithdraw || failedCheckWithdraw) && (
                <button
                  type="button"
                  className="btn btnClose"
                  onClick={() => {
                    closeModal();
                    if (checkMsg === tokenText) {
                      dispatch(logout());
                      localStorage.setItem('lang', userLanguage);
                    }
                  }}
                >
                  <IoMdClose style={iconStyle} />
                </button>
                )}

                {unPrintedTicket && !printError && !printSuccess && !isPrintLoading && (
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => {
                      const { data, ref } = JSON.parse(localStorage.getItem('currentTicket'));
                      if (isNativeApp()) {
                        dispatch(printing());
                        printTicketData({ data, ref });
                      } else {
                        dispatch(printTicket(data));
                      }
                    }}
                  >
                    {t(msg53)}
                  </button>
                )}

                {histMsg.length > 0 && (
                <button
                  type="button"
                  className="btn btnClose"
                  onClick={() => {
                    closeModal();
                    if (histMsg === tokenText) {
                      dispatch(logout());
                      localStorage.setItem('lang', userLanguage);
                    }
                  }}
                >
                  <IoMdClose style={iconStyle} />
                </button>

                )}
                {resetMessage.length > 0 && (
                <button
                  type="button"
                  className="btn btnClose"
                  onClick={() => {
                    closeModal();
                    if (resetMessage === tokenText) {
                      dispatch(logout());
                      localStorage.setItem('lang', userLanguage);
                    }
                  }}
                >
                  <IoMdClose style={iconStyle} />
                </button>

                )}
                {resetSuccessMsg.length > 0 && (
                <>
                  <button
                    type="button"
                    className="btn btnClose"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    <IoMdClose style={iconStyle} />
                  </button>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => {
                      dispatch(hideResetMsg());
                      dispatch(hideResetPinForm());
                      dispatch(logout());
                      localStorage.setItem('lang', userLanguage);
                    }}
                  >
                    {t(msg1)}
                  </button>
                </>
                )}
              </article>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
