/* eslint  max-len: 0 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  authParams, getBase64Hash,
} from 'components/helpers/base64AuthParams';
import tokenText from 'components/helpers/tokenText';
import translationKeys from 'translations/translationKeys';
import ERROR_MESSAGES from 'utils/error-code';
import getLanguage from 'components/helpers/getLanguage';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});
const { msg29 } = translationKeys;
export const verifyTicket = createAsyncThunk(
  'Tickets/verifyTicket',
  async ({ refCode, agentId }, thunkAPI) => {
    const token = JSON.parse(sessionStorage.getItem('token'));
    const lang = getLanguage();
    const api = `/transaction/load/ticket?refcode=${refCode}&agentId=${agentId}&lang=${lang}`;
    const url = `${apiBaseUrl}/${apiVersion2}${api}`;
    const signatureParam = `/transaction/load/ticketrefcode=${refCode}&agentId=${agentId}&lang=${lang}`;
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}`)}`,
          Authorization: token ? `Bearer ${token}` : '',
        },
      };
      const resp = await client.get(url, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        message: error.response.data.message || 'An error occurred',
        erc: error.response.data.erc,
      });
    }
  },
);

const initialState = {
  ticketData: [],
  isTicketLoading: false,
  canPay: false,
  canCancel: false,
  successfetchTicket: false,
  verifyTicketMsg: '',
  isErrorVerifyTckt: '',
};

const verifyTicketSlice = createSlice({
  name: 'verify-ticket',
  initialState,
  reducers: {
    hideVerifyModal: (state) => ({
      ...state,
      isTicketLoading: false,
      successfetchTicket: false,
      verifyTicketMsg: '',
      isErrorVerifyTckt: '',
    }),
  },

  extraReducers: (builder) => {
    builder
      .addCase(verifyTicket.pending, (state) => ({
        ...state,
        isTicketLoading: true,
        isErrorVerifyTckt: '',
      }))
      .addCase(verifyTicket.fulfilled, (state, { payload }) => {
        let canPay = false;
        let canCancel = false;
        const isTicketLoading = false;
        let verifyTicketMsg = '';
        let successfetchTicket = false;

        if (payload[0]) {
          if (payload[0]?.can_pay_infos) {
            if (payload[0].can_pay_infos.erc === '1') {
              canPay = true;
              successfetchTicket = true;
            } else if (payload[0].can_pay_infos.erc === '0') {
              verifyTicketMsg = payload[0].can_pay_infos.msg;
            }
          } else if (payload[0]?.can_cancel_infos) {
            if (payload[0].can_cancel_infos?.b_can_cancel === true) {
              canCancel = true;
              successfetchTicket = true;
            } else if (payload[0].can_cancel_infos?.error_code) {
              const errorCode = payload[0].can_cancel_infos.error_code;
              verifyTicketMsg = ERROR_MESSAGES[errorCode] ?? msg29;
            }
          } else if (payload[0]?.erc === 0) {
            verifyTicketMsg = payload[0].msg;
          } else {
            verifyTicketMsg = msg29;
          }
        }

        // if (payload && payload[0] && !payload[0].can_pay_infos) {
        //   canPay = false;
        //   canCancel = false;
        //   isTicketLoading = false;
        //   verifyTicketMsg = 'Une erreur est survenue sur le serveur';
        // }
        // if (payload && payload[0] && !payload[0].can_cancel_infos) {
        //   canPay = false;
        //   canCancel = false;
        //   isTicketLoading = false;
        //   verifyTicketMsg = 'Une erreur est survenue sur le serveur';
        // }

        return {
          ...state,
          ticketData: payload,
          isTicketLoading,
          successfetchTicket,
          verifyTicketMsg,
          isErrorVerifyTckt: '',
          canPay,
          canCancel,
        };
      })

      .addCase(verifyTicket.rejected, (state, { payload }) => {
        let verifyTicketMsg;
        if (payload && payload.erc && payload.erc === '001') {
          verifyTicketMsg = tokenText;
        } else { verifyTicketMsg = msg29; }
        return {
          ...state,
          isTicketLoading: false,
          isErrorVerifyTckt: msg29,
          verifyTicketMsg,
        };
      });
  },
});

export const { hideVerifyModal } = verifyTicketSlice.actions;
export default verifyTicketSlice.reducer;
