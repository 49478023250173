import React from 'react';
import { Outlet } from 'react-router-dom';
import '../../styles/_single-page.scss';

const SinglePage = () => (
  <div className="content no-bg">
    <Outlet />
  </div>
);
export default SinglePage;
