import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import parsePhoneNumber from 'libphonenumber-js';
import translationKeys from 'translations/translationKeys';
import { useSelector } from 'react-redux';
import { scan } from 'components/helpers/navtiveFunction';
import isNativeApp from 'components/helpers/native';
import { formatCurrency } from 'components/helpers/currencyOptions';
import qrCodeImage from '../../../../assets/images/mathys/svg/qr-code.svg';

const { useTranslation } = require('react-i18next');

const RetailBonusBetSection = ({
  isMobile, setAgentBonus, setRetailBetInfo, disableBet, getAgentBonuses, useAgentBonus,
}) => {
  const { t } = useTranslation();
  const {
    msg187, msg201, msg202, msg203, msg204, msg205, msg206, msg207, msg210, msg211,
  } = translationKeys;
  const [betSelection, setBetSelection] = useState(null);
  const [clientPhoneNumber, setClientPhoneNumber] = useState('');
  const [clientAccountCode, setClientAccountCode] = useState('');
  const [toggleClientBonus, setToggleClientBonus] = useState(false);
  const [clientBonus, setClientBonus] = useState('');
  const [invalidNumber, setInvalidNumber] = useState(true);
  const [invalidAccCode, setInvalidAccCode] = useState(true);
  const [countryCode, setCountryCode] = useState('+243');
  const [country, setCountry] = useState('DC');
  const [phoneLength, setPhoneLength] = useState(9);

  const { data } = useSelector((store) => store.systemConfigs);
  const { agentBonuses, agentHasBonus } = useSelector((store) => store.clientBonus);
  const { currencyConfigs } = useSelector((store) => store.currencyConfigs);
  const { privileges } = useSelector((store) => store.login);

  const resetClientBonus = () => {
    setToggleClientBonus(false);
    setClientBonus('');
  };

  const getCode = () => countryCode.replace('+', '%2B');

  const scanningQRCode = (e) => {
    e.preventDefault();
    scan();
  };

  const getValidPhoneNumber = (event) => {
    const number = event.target.value;
    setClientPhoneNumber(number);
    if (number.length === phoneLength) {
      const validator = parsePhoneNumber(number, country);
      if (validator?.isValid()) {
        setInvalidNumber(false);
        disableBet(false);
        setRetailBetInfo({ rphn: getCode() + number });
      } else {
        setInvalidNumber(true);
        disableBet(true);
        setRetailBetInfo({});
        resetClientBonus();
      }
    } else {
      setInvalidNumber(true);
      disableBet(true);
      setRetailBetInfo({});
      resetClientBonus();
    }
  };

  const getClientBonus = (event) => {
    setClientBonus(event.target.value);

    if (!invalidNumber) {
      setRetailBetInfo({ rphn: getCode() + clientPhoneNumber, bonid: event.target.value });
    }

    if (!invalidAccCode) {
      setRetailBetInfo({ rcc: clientAccountCode, bonid: event.target.value });
    }
  };

  const toggleClientBonusUse = () => {
    if (toggleClientBonus) {
      if (!invalidNumber) {
        setRetailBetInfo({ rphn: getCode() + clientPhoneNumber });
      }

      if (!invalidAccCode) {
        setRetailBetInfo({ rcc: clientAccountCode });
      }
    }
    setToggleClientBonus(!toggleClientBonus);
  };

  const resetRetailRef = () => {
    setClientPhoneNumber('');
    setClientAccountCode('');
    setAgentBonus({});
    setInvalidNumber(true);
    setInvalidAccCode(true);
    resetClientBonus();
  };

  const getClientCode = (event) => {
    const code = event.target.value.toLowerCase();
    setClientAccountCode(code);
    if (code.length === 6) {
      setInvalidAccCode(false);
      disableBet(false);
      setRetailBetInfo({ rcc: code });
    } else {
      setInvalidAccCode(true);
      disableBet(true);
      setRetailBetInfo({});
      resetClientBonus();
    }
  };

  const selectSection = (section) => {
    resetRetailRef();
    if (section === 'bonus') {
      getAgentBonuses();
    }

    if (section === betSelection) {
      setBetSelection(null);
      disableBet(false);
    } else {
      setBetSelection(section);
      disableBet(true);
    }
  };

  const selectAgentBonus = (event) => {
    const bonusSelected = event.target.value;

    if (bonusSelected.length > 0) {
      const selectedBonus = agentBonuses.find((bonus) => bonus.value === bonusSelected);
      if (selectedBonus) {
        setAgentBonus({ ...selectedBonus });
        disableBet(false);
      }
    } else {
      setAgentBonus({});
      disableBet(true);
    }
  };

  const setAccountCode = (accountCode) => {
    setClientAccountCode(accountCode.toLowerCase());
    setInvalidAccCode(false);
    disableBet(false);
    setRetailBetInfo({ rcc: accountCode.toLowerCase() });
  };

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setCountryCode(data.DEFAULT_COUNTRY_CODE);
      setPhoneLength(Number(data.PHONE_NUMBER_LENGTH));
      const randomPhoneNumber = '1'.repeat(Number(data.PHONE_NUMBER_LENGTH));
      const { country } = parsePhoneNumber(data.DEFAULT_COUNTRY_CODE + randomPhoneNumber);
      setCountry(country);
    }
  }, [data]);

  // Expose the function globally
  useEffect(() => {
    window.onQRCodeCallback = (accountCode) => {
      setAccountCode(accountCode);
    };
    return () => {
      delete window.onQRCodeCallback;
    };
  }, []);

  return (
    <div className={`retail-bonus-bet-container${isMobile ? ' mobile' : ''}`}>
      {privileges.retailCustomer && (
      <div className="retail-bet-section">
        <div className="ctn-input checkbox">
          <label htmlFor="retail-bet-checkbox" id="show-retail-bet">
            <input
              type="checkbox"
              id="retail-bet-checkbox"
              checked={betSelection === 'retail'}
              onChange={() => { selectSection('retail'); }}
            />
            <span>{t(msg201)}</span>
          </label>
        </div>
        {betSelection === 'retail' && (
        <div className={`retail-bet-form${isMobile ? ' mobile' : ''}`}>
          <section className="step">
            <div className={`retail-ref${toggleClientBonus ? ' closed' : ''}`}>
              <ul className="ref-selection nav nav-tabs" id="myTab" role="tablist">
                <li className="radio-option" role="presentation">
                  <input type="radio" name="tab" id="account-code" data-bs-toggle="tab" data-bs-target="#account-code-pane" role="tab" aria-controls="account-code-pane" aria-selected="true" defaultChecked onClick={resetRetailRef} />
                  <label htmlFor="account-code">{t(msg203)}</label>
                </li>
                <li className="radio-option" role="presentation">
                  <input type="radio" name="tab" id="tel-number" data-bs-toggle="tab" data-bs-target="#tel-number-pane" role="tab" aria-controls="tel-number-pane" aria-selected="false" onClick={resetRetailRef} />
                  <label htmlFor="tel-number">{t(msg204)}</label>
                </li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane fade show active" id="account-code-pane" role="tabpanel" aria-labelledby="account-code" tabIndex="0">
                  <div className="input-wrap">
                    <input type="text" placeholder={t(msg205)} className="form-control" value={clientAccountCode} onChange={getClientCode} />
                    {clientAccountCode.length > 1 && invalidAccCode && (
                    <div className="error-block">
                      <span>{t(msg206)}</span>
                    </div>
                    )}
                  </div>
                  { isNativeApp() && (
                  <button className="btn btn-icon qr-code" type="button" onClick={scanningQRCode}>
                    <span className="icon" style={{ maskImage: `url(${qrCodeImage})` }} />
                  </button>
                  )}
                </div>
                <div className="tab-pane fade" id="tel-number-pane" role="tabpanel" aria-labelledby="tel-number" tabIndex="0">
                  <div className="tel-input-group">
                    <div className="country">
                      <span>{countryCode}</span>
                    </div>
                    <div className="input-field">
                      <input
                        placeholder="ex: 892651248"
                        id="phoneNumber"
                        type="tel"
                        value={clientPhoneNumber}
                        onChange={getValidPhoneNumber}
                        onKeyDown={(e) => !(/^\d+$/.test(e.key) || e.key === 'Backspace' || e.key === 'Delete') && e.preventDefault()}
                        className={`form-control${(invalidNumber && clientPhoneNumber.length > 0) ? ' error' : ''}`}
                      />
                      {invalidNumber && clientPhoneNumber.length > 0 && (
                      <div className="error-block">
                        <span>{t(msg187)}</span>
                      </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ctn-input checkbox customer-bonus">
              <label htmlFor="client-bonus-checkbox" id="show-client-bonus">
                <input
                  type="checkbox"
                  id="client-bonus-checkbox"
                  disabled={invalidAccCode && invalidNumber}
                  checked={toggleClientBonus}
                  onClick={toggleClientBonusUse}
                />
                <span>{t(msg211)}</span>
              </label>
              {toggleClientBonus && (
              <>
                <input type="text" placeholder={t(msg207)} className="form-control" value={clientBonus} onChange={getClientBonus} />
                {/* <div className="actions">
                    <button className="btn btn-secondary no-border" type="button">
                      <span>{t(msg208)}</span>
                    </button>
                  </div> */}
              </>
              )}
            </div>
          </section>
        </div>
        )}
      </div>
      )}
      { agentHasBonus && (
      <div className="agent-bonus-section">
        <div className="ctn-input checkbox">
          <label htmlFor="agent-bonus-checkbox" id="show-agent-bonus">
            <input
              type="checkbox"
              id="agent-bonus-checkbox"
              checked={betSelection === 'bonus'}
              disabled={!useAgentBonus}
              onChange={() => { selectSection('bonus'); }}
            />
            <span>{t(msg202)}</span>
          </label>
        </div>
        {betSelection === 'bonus' && (
        <select defaultValue="" className="form-control bonus-selection" onChange={selectAgentBonus}>
          <option value="">{t(msg210)}</option>
          {agentBonuses.map((agentBonus) => (
            <option value={agentBonus.value} key={agentBonus.value}>{`${agentBonus.name} (${formatCurrency(agentBonus.amount, currencyConfigs)})`}</option>
          ))}
        </select>
        )}
      </div>
      )}
    </div>
  );
};

RetailBonusBetSection.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  setAgentBonus: PropTypes.func.isRequired,
  setRetailBetInfo: PropTypes.func.isRequired,
  getAgentBonuses: PropTypes.func.isRequired,
  disableBet: PropTypes.func.isRequired,
  useAgentBonus: PropTypes.bool.isRequired,
};

export default RetailBonusBetSection;
