import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { balanceObj, userObj } from 'components/common/requestBody';
import { getBalance } from 'redux/solde/getBalanceSlice';
import translationKeys from 'translations/translationKeys';
import { navigatorLang } from 'components/helpers/getLanguage';
import fileText from '../../../../assets/images/mathys/svg/fileText.svg';

const Solde = () => {
  const { agentId, agentPwd } = useSelector((store) => store.login);
  const { successGetBalance } = useSelector((store) => store.balance);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  const handleChange = () => {
    const dateLabel = document.getElementById('datelabel').value;
    if (dateLabel !== '') {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const date = new Date(e.target.elements.datelabel.value);
    const timestamp = date.getTime();
    dispatch(getBalance({
      ...balanceObj,
      date: timestamp,
      uuid: agentId,
      agentPwd,
      imei: userObj.imei,
      ime: userObj.imei,
    }));
  };

  const {
    msg20, msg63, msg55, msg195, msg199,
  } = translationKeys;

  useEffect(() => {
    if (successGetBalance) {
      document.getElementById('journal-sold-form').reset();
    }
  },
  [successGetBalance]);

  return (
    <div className="details history">
      <div className="header">
        <img src={fileText} alt="" />
        <span>{t(msg20)}</span>
      </div>
      <div className="content">
        <form action="" onSubmit={handleSubmit} className="solde-form" id="journal-sold-form">
          <div className="content-grid">
            <div className="input-wrap">
              <label htmlFor="datelabel">
                {t(msg63)}
              </label>
              <input
                type="date"
                className={`form-control solde-input${!disabled ? ' has-value' : ''}`}
                id="datelabel"
                placeholder={navigatorLang() === 'fr' ? t(msg195) : t(msg199)}
                onChange={handleChange}
                required
              />
            </div>
            <button
              type="submit"
              className="btn btn-secondary"
              disabled={disabled}
            >
              <span>{t(msg55)}</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Solde;
