import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import translationKeys from 'translations/translationKeys';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMultipleBet } from 'redux/configs/betConfigsSlice';
import trash from '../../../../assets/images/mathys/svg/trash.svg';
import backIcon from '../../../../assets/images/mathys/svg/chevron-left.svg';

const BetSlipHeader = ({
  isMobile, closeMobileBetSlip, betCount, switchBetType,
  switchTo, clearBetSlip,
}) => {
  const dispatch = useDispatch();
  const {
    bettingOptions, toggleToMultiple, betType,
    toggleToMultipleSimple,
  } = useSelector((store) => store.betSlip);
  const { msg153, msg74 } = translationKeys;
  const { t } = useTranslation();
  const betTypes = (`${bettingOptions}`).split(',');
  const simpleBetIndex = betTypes.lastIndexOf('SIMPLE');
  const multipleBetIndex = betTypes.lastIndexOf('MULTIPLE');
  const multipleSimpleBetIndex = betTypes.lastIndexOf('MULTI_SIMPLE');

  const toggleMultiple = (betType) => {
    dispatch(toggleMultipleBet(betType));
  };

  useEffect(() => {
    if (toggleToMultiple) {
      dispatch(toggleMultipleBet(betTypes[multipleBetIndex]));
    }
  }, [toggleToMultiple]);

  useEffect(() => {
    if (toggleToMultipleSimple) {
      dispatch(toggleMultipleBet(betTypes[multipleSimpleBetIndex]));
    }
  }, [toggleToMultipleSimple]);

  return (
    <div className="header-container">
      {isMobile ? (
        <div className="header">
          <nav className="event-header">
            <button className="event-nav" type="button" onClick={closeMobileBetSlip}>
              <img src={backIcon} className="arrow" alt="tab" />
              <span className="text-uppercase">{t(msg153)}</span>
            </button>
          </nav>
        </div>
      ) : (
        <div className="head">
          <span>{t(msg153)}</span>
          <span className="count">{betCount}</span>
        </div>
      )}

      {betType !== '' && (
      <div className="sub-header">
        <select name="" id="bet-type-select" defaultValue={betType} onChange={(e) => toggleMultiple(e.target.value)} disabled={!switchBetType}>
          <option value={betType}>
            {betType === betTypes[multipleSimpleBetIndex] ? betTypes[simpleBetIndex] : betType }
          </option>
          { switchTo.multiple ? (
            <option value={betTypes[multipleBetIndex]}>
              {betTypes[multipleBetIndex]}
            </option>
          ) : null}
          { switchTo.simple ? (
            <option value={betTypes[multipleSimpleBetIndex]}>
              {betTypes[simpleBetIndex]}
            </option>
          ) : null}
        </select>
        <button
          type="button"
          className="btn btn-link"
          onClick={clearBetSlip}
        >
          {t(msg74)}
          <img src={trash} alt="" />
        </button>
      </div>
      )}
    </div>
  );
};

BetSlipHeader.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  closeMobileBetSlip: PropTypes.func.isRequired,
  clearBetSlip: PropTypes.func.isRequired,
  betCount: PropTypes.number.isRequired,
  switchBetType: PropTypes.bool.isRequired,
  switchTo: PropTypes.shape({
    multiple: PropTypes.bool.isRequired,
    simple: PropTypes.bool.isRequired,
  }).isRequired,

};

export default BetSlipHeader;
