import React from 'react';
import { Navigate } from 'react-router';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import NavigationPanel from './navigationPanel/NavigationPanel';

const Dashboard = ({ isMobile }) => {
  const {
    isLoggedIn,
  } = useSelector((store) => store.login);

  return isLoggedIn ? <NavigationPanel isMobile={isMobile} /> : <Navigate to="../../login" replace />;
};

Dashboard.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default Dashboard;
