import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { authParams, getBase64Hash } from 'components/helpers/base64AuthParams';
import axios from 'axios';
import { userObj } from 'components/common/requestBody';
import getLanguage from 'components/helpers/getLanguage';
import translationKeys from 'translations/translationKeys';
import ERROR_MESSAGES from 'utils/error-code';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const {
  msg29, msg188, msg193, msg194, msg200, msg209,
} = translationKeys;
const client = axios.create({
  baseURL: apiBaseUrl,
});

export const registerCustomer = createAsyncThunk('customer-account/creation',
  async (params, thunkAPI) => {
    const body = {
      ...params,
      lang: getLanguage(),
      channel: userObj.chl,
    };
    const api = '/customer-service/register-retail';
    const url = `${apiBaseUrl}/${apiVersion2}${api}`;

    try {
      const dataToHash = `${authParams.username}/${apiVersion2}${api}${JSON.stringify(body)}`;
      const headers = {
        'Content-Type': 'application/json; charset=UTF-8',
        'Content-Signature': `${authParams.username}:${getBase64Hash(dataToHash)}`,
      };

      const response = await client.post(url, body, { headers });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(msg29);
    }
  });

export const validateCustomerActivation = createAsyncThunk('customer-account/activation-validation',
  async (params, thunkAPI) => {
    const body = {
      ...params,
      lang: getLanguage(),
      channel: userObj.chl,
      channel_id: userObj.chl,
    };
    const api = '/user/verifyAccountBySms';
    const url = `${apiBaseUrl}/${apiVersion2}${api}`;

    try {
      const dataToHash = `${authParams.username}/${apiVersion2}${api}${JSON.stringify(body)}`;
      const headers = {
        'Content-Type': 'application/json; charset=UTF-8',
        'Content-Signature': `${authParams.username}:${getBase64Hash(dataToHash)}`,
      };

      const response = await client.post(url, body, { headers });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(msg29);
    }
  });

export const resendCustomerActivationCode = createAsyncThunk('customer-account/resend-activation-code',
  async (phone, thunkAPI) => {
    const body = {
      phone,
      lang: getLanguage(),
      channel: userObj.chl,
      channel_id: userObj.chl,
    };
    const api = '/user/resendSMSActivationCode';
    const url = `${apiBaseUrl}/${apiVersion2}${api}`;

    try {
      const dataToHash = `${authParams.username}/${apiVersion2}${api}${JSON.stringify(body)}`;
      const headers = {
        'Content-Type': 'application/json; charset=UTF-8',
        'Content-Signature': `${authParams.username}:${getBase64Hash(dataToHash)}`,
      };

      const response = await client.post(url, body, { headers });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(msg29);
    }
  });

const initialState = {
  accountCreation: {
    isLoading: false,
    success: false,
    message: '',
    retailCustomerId: '',
  },
  accountActivation: {
    isLoading: false,
    success: false,
    message: '',
  },
  accountValidation: {
    isLoading: false,
    success: false,
    message: '',
    accountCode: '',
    phoneNumber: '',
  },
  resendActivationCode: {
    isLoading: false,
    success: false,
    message: '',
  },
};

const customerAccountManagementSlice = createSlice({
  name: 'customer-management',
  initialState,
  reducers: {
    resetAccountCreation: (state) => ({
      ...state,
      accountCreation: initialState.accountCreation,
    }),
    resetAccountCreationMessage: (state) => ({
      ...state,
      accountCreation: { ...state.accountCreation, message: '' },
    }),
    resetAccountValidation: (state) => ({
      ...state,
      accountValidation: initialState.accountValidation,
    }),
    resetResendActivationCode: (state) => ({
      ...state,
      resendActivationCode: initialState.resendActivationCode,
    }),
  },

  extraReducers: (builder) => {
    builder
      .addCase(registerCustomer.pending, (state) => ({
        ...state,
        accountCreation: {
          ...state.accountCreation,
          isLoading: true,
          success: false,
        },
      }))
      .addCase(registerCustomer.fulfilled, (state, { payload }) => {
        const { erc } = payload;
        let message = '';
        let success = false;
        let retailCustomerId = '';

        if (Number(erc) === 1) {
          message = msg188;
          success = true;
          retailCustomerId = payload.id;
        } else {
          message = ERROR_MESSAGES[erc] ?? msg29;
        }

        return {
          ...state,
          accountCreation: {
            isLoading: false,
            success,
            retailCustomerId,
            message,
          },
        };
      })
      .addCase(registerCustomer.rejected, (state) => ({
        ...state,
        accountCreation: {
          isLoading: false,
          success: false,
          message: msg29,
        },
      }))
      .addCase(validateCustomerActivation.pending, (state) => ({
        ...state,
        accountValidation: {
          ...state.accountValidation,
          isLoading: true,
          success: false,
        },
      }))
      .addCase(validateCustomerActivation.fulfilled, (state, { payload }) => {
        const { erc } = payload;
        let message = '';
        let success = false;
        let accountCode = '';
        let phoneNumber = '';

        if (Number(erc) === 1) {
          message = msg194;
          success = true;
          accountCode = payload.acc;
          phoneNumber = payload.phn;
        } else if ((Number(erc) === 0)) {
          message = msg200;
        } else {
          message = ERROR_MESSAGES[erc] ?? msg29;
        }

        return {
          ...state,
          accountValidation: {
            isLoading: false,
            success,
            accountCode,
            phoneNumber,
            message,
          },
        };
      })
      .addCase(validateCustomerActivation.rejected, (state) => ({
        ...state,
        accountValidation: {
          isLoading: false,
          success: false,
          message: msg29,
        },
      }))
      .addCase(resendCustomerActivationCode.pending, (state) => ({
        ...state,
        resendActivationCode: {
          ...state.resendActivationCode,
          isLoading: true,
        },
      }))
      .addCase(resendCustomerActivationCode.fulfilled, (state, { payload }) => {
        const { erc, data } = payload;
        let message = '';
        let success = false;
        let { accountCreation } = state;

        if (Number(erc) === 1) {
          message = msg193;
          success = true;
          accountCreation = { ...accountCreation, retailCustomerId: data.lg_AGENT_ID };
        } else if (Number(erc) === 0) {
          message = msg209;
        } else {
          message = ERROR_MESSAGES[erc] ?? msg29;
        }

        return {
          ...state,
          accountCreation,
          resendActivationCode: {
            isLoading: false,
            success,
            message,
          },
        };
      })
      .addCase(resendCustomerActivationCode.rejected, (state) => ({
        ...state,
        resendActivationCode: {
          isLoading: false,
          success: false,
          message: msg29,
        },
      }));
  },
});

export const {
  resetAccountCreation, resetAccountValidation,
  resetResendActivationCode, resetAccountCreationMessage,
} = customerAccountManagementSlice.actions;

export default customerAccountManagementSlice.reducer;
