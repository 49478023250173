import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import LeftSideBar from './LeftSideBar';
import BetSlip from './BetSlip/BetSlip';
import '../../../styles/_dashboard.scss';

const Body = ({ isMobile }) => {
  const navigate = useNavigate();
  const [toggleBetSlip, setToggleBetSlip] = useState(false);

  const closeBetSlip = () => setToggleBetSlip(false);
  const openBetSlip = () => setToggleBetSlip(true);

  const { success } = useSelector((store) => store.placeBets);
  const { printError, printSuccess } = useSelector((store) => store.printTicket);

  useEffect(() => {
    if ((printSuccess || printError) && success && isMobile) {
      closeBetSlip();
      navigate('/dashboard/home');
    }
  }, [success, printError, printSuccess, isMobile, navigate]);

  return (
    <div className="body">
      <LeftSideBar isMobile={isMobile} openMobileBetSlip={openBetSlip} />
      <Outlet />
      <BetSlip isMobile={isMobile} toggleBetSlip={toggleBetSlip} closeBetSlip={closeBetSlip} />
    </div>
  );
};

Body.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default Body;
