/* eslint  no-restricted-syntax: 0 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import translationKeys from 'translations/translationKeys';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API_PRINT;
const client = axios.create({
  baseURL: apiBaseUrl,
});

const { msg159, msg220 } = translationKeys;

export const printTicket = createAsyncThunk(
  'Tickets/print ticket',
  async (ticket, thunkAPI) => {
    // const encodedData = encodeURIComponent(JSON.stringify(ticket));
    // const api = `/print?ticket=${encodedData}`;
    const api = '/print-esc-pos';
    try {
      // const resp = await client.get(api);
      // remove the two last lines that content the flexbet signature
      let lines = [...ticket];
      lines.splice(lines.length - 2, 2);
      lines = lines.map((line) => line.trim());
      const bonusIndex = lines.findIndex((line) => line.includes('Bonus'));
      if (bonusIndex > -1) {
        lines.splice(bonusIndex, 1);
        lines.splice(5, 1, `${lines[5]} - FREE BET`);
      }
      const resp = await client.post(api, { lines }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return resp.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue({
        printData: null,
        isPrintLoading: false,
        printError: true,
      });
    }
  },
);

const initialState = {
  printSuccess: false,
  isPrintLoading: false,
  printError: false,
  unPrintedTicket: false,
  checkedUnPrintedTicket: false,
  printErrorMessage: '',
};

const printTicketSlice = createSlice({
  name: 'print ticket',
  initialState,
  reducers: {
    refreshPrinting: (state) => ({ ...state, ...initialState }),
    printing: (state) => ({ ...state, isPrintLoading: true, printError: false }),
    checkUnprintedTicket: (state) => {
      const unPrintedTicket = localStorage.getItem('currentTicket') !== null;

      return {
        ...state, unPrintedTicket, checkedUnPrintedTicket: true,
      };
    },
    setPrintStatus: (state, { payload }) => {
      if (payload.status) {
        localStorage.removeItem('currentTicket');
      }

      return {
        ...state,
        printError: !payload.status,
        printSuccess: payload.status,
        printErrorMessage: payload.status ? payload.msg : '',
        checkedUnPrintedTicket: false,
        isPrintLoading: false,
      };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(printTicket.pending, (state) => ({
        ...state,
        isPrintLoading: true,
        printError: false,
      }))
      .addCase(printTicket.fulfilled, (state, { payload }) => {
        let printSuccess = false;
        let printError = true;
        let printErrorMessage = '';

        if (Number(payload?.erc) === 1) {
          localStorage.removeItem('currentTicket');
          printSuccess = true;
          printError = false;
        } else if (Number(payload?.erc) === 404) {
          printErrorMessage = msg220;
        } else {
          printErrorMessage = msg159;
        }

        return {
          ...state,
          printSuccess,
          isPrintLoading: false,
          printError,
          checkedUnPrintedTicket: false,
          printErrorMessage,
        };
      })

      .addCase(printTicket.rejected, (state) => ({
        ...state,
        isPrintLoading: false,
        printSuccess: false,
        printError: true,
        checkedUnPrintedTicket: false,
        printErrorMessage: msg159,
      }));
  },
});

export const {
  refreshPrinting, checkUnprintedTicket,
  setPrintStatus, printing,
} = printTicketSlice.actions;

export default printTicketSlice.reducer;
