import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkWithdrawObj, userObj } from 'components/common/requestBody';
import { checkWithdraw } from 'redux/withdrawal/checkWithdrawalSlice';
import { useTranslation } from 'react-i18next';
import translationKeys from 'translations/translationKeys';
import withdrawal from '../../../../assets/images/mathys/svg/withdrawal.svg';

const Withdrawal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { agentId, agentPhone, agentPwd } = useSelector((store) => store.login);
  const { withdrawn } = useSelector((store) => store.withdrawWithCode);
  const [disabled, setDisabled] = useState(true);
  const {
    msg19, msg73, msg85, msg84, msg72, msg55,
  } = translationKeys;

  const handleChange = () => {
    const codeClient = document.getElementById('codeClient').value;
    const codeOtp = document.getElementById('codeOtp').value;
    if (codeClient !== '' && codeOtp !== '') {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const checkWithdrawal = (e) => {
    e.preventDefault();
    const codeClient = e.target.elements.codeClient.value;
    const codeOtp = e.target.elements.codeOtp.value;
    dispatch(checkWithdraw({
      ...checkWithdrawObj,
      agentId,
      agentPwd,
      agentPhone,
      imei: userObj.imei,
      cac: codeClient,
      wcd: codeOtp,
    }));
  };

  useEffect(() => {
    if (withdrawn) {
      setDisabled(true);
      document.getElementById('withdraw-with-code-form').reset();
    }
  }, [withdrawn]);

  return (
    <div className="details history">
      <div className="header">
        <img src={withdrawal} alt="" />
        <span>{t(msg19)}</span>
      </div>
      <div className="content">
        <form id="withdraw-with-code-form" action="" onSubmit={checkWithdrawal} className="withdraw-form">
          <div className="content-grid">
            <div className="input-wrap">
              <label htmlFor="codeClient" className="form-label ">
                {t(msg72)}
              </label>
              <input
                type="text"
                className="form-control withdraw-input"
                id="codeClient"
                placeholder={t(msg84)}
                onChange={handleChange}
                required
              />
            </div>
            <div className="input-wrap">
              <label htmlFor="codeOtp" className="form-label ">
                {t(msg73)}
              </label>
              <input
                type="text"
                className="form-control withdraw-input"
                id="codeOtp"
                placeholder={t(msg85)}
                onChange={handleChange}
                required
              />
            </div>
            <button
              type="submit"
              className="btn btn-secondary"
              disabled={disabled}
            >
              <span>{t(msg55)}</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Withdrawal;
