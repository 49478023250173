/* eslint  jsx-a11y/click-events-have-key-events: 0 */
/* eslint  jsx-a11y/no-static-element-interactions: 0 */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { userObj } from 'components/common/requestBody';
import { resetPin, hideResetPinForm } from 'redux/reset-pin/resetPinSlice';
import translationKeys from 'translations/translationKeys';
import PropTypes from 'prop-types';
import { getUserInfo } from 'components/helpers/checkToken';
import crossImage from '../../assets/images/mathys/svg/x-lg.svg';
import backIcon from '../../assets/images/mathys/svg/chevron-left.svg';

const ResetPinComponent = ({
  containerClass, open, close, isMobile, closeButton,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { agentId, agentPhone, agentPwd } = useSelector((store) => store.login);
  const [isShown, setIsSHown] = useState(false);
  const { resetSuccessMsg } = useSelector((store) => store.resetPinData);
  const {
    msg7, msg27, msg99, msg8, msg100, msg136, msg137, msg101, msg102,
    msg103, msg104, msg55, msg176, msg177,
  } = translationKeys;

  let pinValidation = Yup.string().required(t(msg7));

  if (agentPwd.length > 0) {
    pinValidation = pinValidation.oneOf([agentPwd, null], t(msg27));
  } else {
    const tempUserInfo = getUserInfo(true);
    if ('agentPwd' in tempUserInfo) {
      pinValidation = pinValidation.oneOf([tempUserInfo.agentPwd, null], t(msg27));
    }
  }

  const formik = useFormik({
    initialValues: {
      pin: '',
      newPin: '',
      confirmPin: '',
      phone: agentPhone,
      matricule: agentId,
      channel: userObj.chl,
      strFrom: agentPhone,
      ime: userObj.imei,
    },
    validationSchema: Yup.object({
      pin: pinValidation,
      newPin: Yup.string().required(t(msg99)).notOneOf([Yup.ref('pin')], t(msg100)),
      confirmPin: Yup.string()
        .required(t(msg136))
        .oneOf([Yup.ref('newPin'), null], t(msg137)),
    }),
    onSubmit: (values) => {
      dispatch(resetPin(values));
    },
  });

  const togglePassword = () => {
    setIsSHown((isShown) => !isShown);
  };

  useEffect(() => {
    if (resetSuccessMsg.length > 0) {
      document.getElementById('reset-pin-form').reset();
    }
  }, [resetSuccessMsg, dispatch]);

  return (
    <div className={isMobile ? containerClass + (open ? ' open' : '') : containerClass}>
      <div className="login">
        <div
          className="content "
          onClick={(e) => {
            if ((e.target === e.currentTarget) && !isMobile) {
              dispatch(hideResetPinForm());
            }
          }}
        >
          <div
            className={`panel bg-white reset-pin-form${closeButton ? ' rounded' : ''}`}
            onClick={(e) => e.stopPropagation()}
          >
            {isMobile && !closeButton && (
            <button className="btn btn-back" type="button" onClick={close}>
              <span className="icon" style={{ maskImage: `url(${backIcon})` }} />
              <span>{t(msg101)}</span>
            </button>
            )}
            {(!isMobile || closeButton) && (
            <button
              className="btn btn-close-modal"
              type="button"
              onClick={() => {
                dispatch(hideResetPinForm());
              }}
            >
              <span className="icon" style={{ maskImage: `url(${crossImage})` }} />
            </button>
            )}
            {!isMobile && (<h2>{t(msg101)}</h2>)}
            <form onSubmit={formik.handleSubmit} onReset={formik.handleReset} id="reset-pin-form">
              <div className="ctn-input">
                <label htmlFor="pin" className="form-label">
                  {t(msg102)}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="pin"
                  placeholder={t(msg176)}
                  aria-describedby="pin"
                  value={formik.values.pin}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.pin && formik.errors.pin ? (
                  <div id="pin" className="form-text">{formik.errors.pin}</div>

                ) : null}
              </div>
              <div className="ctn-input">
                <label htmlFor="newPin" className="form-label">
                  {t(msg103)}
                </label>
                <input
                  type={isShown ? 'text' : 'password'}
                  className="form-control"
                  id="newPin"
                  placeholder={t(msg177)}
                  name="newPin"
                  value={formik.values.newPin}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.newPin && formik.errors.newPin ? (
                  <div id="anewPin" className="form-text">{formik.errors.newPin}</div>

                ) : null}
              </div>
              <div className="ctn-input">
                <label htmlFor="confirmPin" className="form-label">
                  {t(msg104)}
                </label>
                <input
                  type={isShown ? 'text' : 'password'}
                  className="form-control"
                  id="confirmPin"
                  placeholder={t(msg177)}
                  name="confirmPin"
                  value={formik.values.confirmPin}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.confirmPin && formik.errors.confirmPin && <div className="form-text">{formik.errors.confirmPin}</div>}
              </div>
              <div className="ctn-input checkbox">
                <label htmlFor="checkbox" className="gray" id="show-password">
                  <span>{t(msg8)}</span>
                  <input
                    type="checkbox"
                    id="checkbox"
                    checked={isShown}
                    onChange={togglePassword}
                  />
                </label>
              </div>
              <button
                type="submit"
                className="btn btn-secondary"
              >
                <span>{t(msg55)}</span>
              </button>

            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

ResetPinComponent.propTypes = {
  containerClass: PropTypes.string.isRequired,
  open: PropTypes.bool,
  closeButton: PropTypes.bool,
  isMobile: PropTypes.bool,
  close: PropTypes.func,
};

ResetPinComponent.defaultProps = {
  open: false,
  isMobile: false,
  closeButton: false,
  close: () => {},
};

export default ResetPinComponent;
