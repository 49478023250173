/* eslint  max-len: 0 */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import '../../../../styles/_sports.scss';
import Loading from 'components/common/Loading';
import { getTomatchesMkts } from 'redux/meilleursParis/topmatchesMktsSlice';
import { getMeilleursParis } from 'redux/meilleursParis/meilleursParisSlice';
import { useTranslation } from 'react-i18next';
import formatData from 'components/helpers/formateData';
import { v4 as uuidv4 } from 'uuid';
import translationKeys from 'translations/translationKeys';
import getSortedMatches from 'components/helpers/sortedMatches';
import Error from 'components/common/Error';
import PropTypes from 'prop-types';
import SportItem from '../SportItem';
import defaultImage from '../../../../assets/images/mathys/png/football.png';
import baseUrls from '../../../../config';

const imgUrl = baseUrls.BASE_URL_IMG;

const MeilleursParis = ({ isMobile }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { msg154 } = translationKeys;
  const {
    meilleursParis, filters, isLoading, error, successTopMatches,
  } = useSelector((store) => store.meilleursParis);
  const { topMatchesMkts, successTopMatchesMkts } = useSelector((store) => store.topMatchesMkts);
  const [sortedMatches, setSortedMatches] = useState([]);
  const [topFilters, setTopFilters] = useState([]);
  const [topSelectedSport, setTopSelectedSport] = useState(localStorage.getItem('topSportSelected'));

  const getSportMatches = (sportId) => {
    if (meilleursParis.length > 0) {
      const topSelectedSportMatches = meilleursParis.find(({ id }) => id === sportId);

      if (topSelectedSportMatches) {
        setSortedMatches(getSortedMatches(topSelectedSportMatches));
      } else {
        setSortedMatches([]);
      }
    } else {
      setSortedMatches([]);
    }
  };

  const getTopParis = () => {
    if (Object.keys(topMatchesMkts).length > 0) {
      dispatch(getMeilleursParis({
        markets: formatData(topMatchesMkts),
        allMarketsRequired: false,
      }));
    }
  };

  const getTopSportParis = (sportId) => {
    const topSelectedSportMatches = meilleursParis.find(({ id }) => id === sportId);

    if (!topSelectedSportMatches?.categories) {
      setSortedMatches([]);
      if (Object.keys(topMatchesMkts).length > 0) {
        dispatch(getMeilleursParis({
          markets: formatData(topMatchesMkts),
          allMarketsRequired: false,
          sport: sportId,
        }));
      }
    }
    setTopSelectedSport(sportId);
    localStorage.setItem('topSportSelected', sportId);
  };
  // When page is refreshed and path is /dashboard/meilleurs-paris, call top matches.

  useEffect(() => {
    if (!successTopMatchesMkts) {
      dispatch(getTomatchesMkts());
    } else if (!successTopMatches) {
      getTopParis();
    } else {
      setTopFilters(filters);
      if (topSelectedSport) {
        getSportMatches(topSelectedSport);
      } else {
        const firstSportMatches = meilleursParis[0];
        setSortedMatches(getSortedMatches(firstSportMatches));
      }
    }
  }, [successTopMatches, topSelectedSport, successTopMatchesMkts, dispatch]);

  return (
    <div className="list-dashboard">
      <div className="content-wrap">
        <div className="tab-content">
          <div className="zone-nav">
            <ul className="nav nav-pills list-inline">
              {topFilters?.length > 0 ? topFilters.map((item) => item && (
              <li className={`nav-item list-inline-item ${item.id === topSelectedSport ? ' active' : ''}`} key={item.id}>
                <button
                  type="button"
                  className="nav-link"
                  onClick={() => {
                    getTopSportParis(item.id);
                    localStorage.setItem('topSportSelected', item.id); // To be used when page is refreshed to refetch data
                    navigate('/dashboard/meilleurs-paris');
                  }}
                >
                  <img
                    src={imgUrl + item.icon || defaultImage}
                    alt={item.name}
                  />
                  <span>{item.name}</span>
                </button>
              </li>
              )) : null}
            </ul>
          </div>
          <div className="tab-content list">
            {isLoading && <Loading />}
            {error && !isLoading && <Error text={t(error)} />}
            {!isLoading && !error && (
            <ul className="match-list">
              { sortedMatches.length > 0 ? sortedMatches.map((match) => <SportItem event={match} isMobile={isMobile} key={match.id + uuidv4()} redirect="/dashboard/meilleurs-paris" />) : <Error text={t(msg154)} /> }
            </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

MeilleursParis.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default MeilleursParis;
