import crypto from 'crypto-js';
import config from '../../config';

const checkToken = () => {
  const expireAt = JSON.parse(sessionStorage.getItem('expireAt'));
  const currentTime = Date.now();
  return (expireAt && (expireAt < currentTime));
};

const setUserInfo = (userInfo, temp = false) => {
  const sessionKey = temp ? 'tempUserInfo' : 'userInfo';
  const data = JSON.stringify(userInfo);
  const encryptedData = crypto.AES.encrypt(data, config.ENCRYPTION_KEY).toString();
  sessionStorage.setItem(sessionKey, encryptedData);
};

const setUserPhoneNumber = (phoneNumber) => {
  const encryptedData = crypto.AES.encrypt(phoneNumber, config.ENCRYPTION_KEY).toString();
  sessionStorage.setItem('agentPhone', encryptedData);
};

const getUserInfo = (temp = false) => {
  const sessionKey = temp ? 'tempUserInfo' : 'userInfo';
  const encryptedData = sessionStorage.getItem(sessionKey);
  if (encryptedData) {
    const decryptedData = crypto.AES.decrypt(encryptedData, config.ENCRYPTION_KEY);

    if (decryptedData) {
      return JSON.parse(decryptedData.toString(crypto.enc.Utf8));
    }

    return {};
  }

  return {};
};

const getUserPhoneNumber = () => {
  const encryptedData = sessionStorage.getItem('agentPhone');
  if (encryptedData) {
    const decryptedData = crypto.AES.decrypt(encryptedData, config.ENCRYPTION_KEY);

    if (decryptedData) {
      return decryptedData.toString(crypto.enc.Utf8);
    }

    return null;
  }

  return null;
};

export {
  setUserInfo, getUserInfo, setUserPhoneNumber, getUserPhoneNumber,
};
export default checkToken;
