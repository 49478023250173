import { createSlice } from '@reduxjs/toolkit';

const headerTitle = JSON.parse(localStorage.getItem('headerTitle'));
const initialState = {
  dashboard: headerTitle?.dashboard,
  details: headerTitle?.details,
  tournament: headerTitle?.tournament,
};

export const navHeaderSlice = createSlice({
  name: 'navHeader',
  initialState,
  reducers: {
    setDashboard: (state, action) => ({
      ...state,
      dashboard: action.payload,
    }),
    setDetails: (state, action) => ({
      ...state,
      details: action.payload,
    }),
    setTournament: (state, action) => ({
      ...state,
      tournament: action.payload,
    }),
    resetHeader: (state) => ({
      ...state,
      dashboard: null,
      details: null,
      tournament: null,
    }),
  },
});

export const {
  setDashboard, setTournament, setDetails, resetHeader,
} = navHeaderSlice.actions;

export default navHeaderSlice.reducer;
