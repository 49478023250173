/* eslint no-param-reassign: 0 */
/* eslint  max-len: 0 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { authParams, getBase64Hash } from 'components/helpers/base64AuthParams';
import translationKeys from 'translations/translationKeys';
import { userObj } from 'components/common/requestBody';
import getLanguage from 'components/helpers/getLanguage';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});
const { msg29, msg154 } = translationKeys;
export const getTodayMatches = createAsyncThunk(
  'sports/getTodayMatches',
  async (sportId, thunkAPI) => {
    const lang = getLanguage();
    const signatureParam = `/sport/paridujour/${sportId}lang=${lang}&channel_id=${userObj.chl}`;
    const api = `/sport/paridujour/${sportId}?lang=${lang}&channel_id=${userObj.chl}`;

    const url = `${apiBaseUrl}/${apiVersion2}${api}`;
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}`)}`,
        },
      };
      const resp = await client.get(url, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(msg29);
    }
  },
);

const initialState = {

  todayMatches: {},
  isLoading: true,
  error: '',
  successTodayMatches: false,
};

const todayMatchesSlice = createSlice({
  name: 'todayMatches',
  initialState,
  reducers: {

    selectItem: (state, { payload }) => {
      state.todayMatches.categories.forEach((cat) => {
        const { tournaments } = cat;
        tournaments.forEach((t) => {
          const { matches } = t;
          matches.forEach((m) => {
            m.outcome = m.outcome.map((o) => (o.uniqueId === payload ? { ...o, inBetSlip: true } : o));
          });
        });
      });
      return state;
    },
    unSelectItem: (state, { payload }) => {
      state.todayMatches.categories.forEach((cat) => {
        const { tournaments } = cat;
        tournaments.forEach((t) => {
          const { matches } = t;
          matches.forEach((m) => {
            m.outcome = m.outcome.map((o) => (o.uniqueId === payload ? { ...o, inBetSlip: false } : o));
          });
        });
      });
      return state;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getTodayMatches.pending, (state) => ({
        ...state,
        isLoading: true,
        error: '',
      }))
      .addCase(getTodayMatches.fulfilled, (state, action) => {
        let successTodayMatches = false;
        let error = '';
        const updatedTodayMatches = {
          ...action.payload,
          categories: action.payload?.categories?.length > 0 && action.payload.categories.map((cat) => ({
            ...cat,
            tournaments: cat.tournaments.map((t) => {
              if (t.matches && t.matches.length === 0) {
                error = msg154;
              } else if (t.matches && t.matches.length > 0) {
                error = '';
              }
              return {
                ...t,
                matches: t.matches.map((m) => {
                  if (m.outcome && m.outcome.length === 0) {
                    error = msg154;
                  } else if (m.outcome && m.outcome.length > 0) {
                    error = '';
                  }
                  return {
                    ...m,
                    outcome: m.outcome.map((outcome) => ({
                      ...outcome,
                      inBetSlip: false,
                      matchId: m.id,
                      uniqueId: m.id + outcome.name + outcome.market.name,
                      count: m.count,
                    })),
                  };
                }),
              };
            }),
          })),
        };
        if (updatedTodayMatches?.categories?.length > 0) {
          successTodayMatches = true;
        }
        return {
          ...state,
          todayMatches: updatedTodayMatches,
          successTodayMatches,
          isLoading: false,
          error,
        };
      })

      .addCase(getTodayMatches.rejected, (state) => ({
        ...state,
        isLoading: false,
        successTodayMatches: false,
        error: msg29,
      }));
  },
});

export const {
  selectItem,
  unSelectItem,
  setAllinBetSlipToFalse,
} = todayMatchesSlice.actions;

export default todayMatchesSlice.reducer;
