/* eslint  max-len: 0 */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showResetPinForm } from 'redux/reset-pin/resetPinSlice';
import { hideResetPinModal } from 'redux/login/loginSlice';
import { useTranslation } from 'react-i18next';
import { IoMdClose } from 'react-icons/io';
import { MessageContent } from './ModalContents';
import '../../styles/_modal.scss';
import logo from '../../assets/images/mathys/png/logo.png';

const LoginModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [className, setClassName] = useState('modalContainer');
  const { loginMessage, resetPinMessage } = useSelector((store) => store.login);
  let content = '';
  if (loginMessage && loginMessage.length > 0) {
    content = <MessageContent text={t(loginMessage)} color="red" />;
  }
  if (resetPinMessage && resetPinMessage.length > 0) {
    content = <MessageContent text={t(resetPinMessage)} color="red" />;
  }
  const iconStyle = {
    color: 'black',
    fontSize: '1.5rem',
  };

  const timeout = 200;
  const closeModal = () => {
    if (loginMessage && loginMessage.length > 0) {
      setClassName('go-back-to-top');
      setTimeout(() => {
        dispatch(hideResetPinModal());
      }, timeout);
    }
    if (resetPinMessage && resetPinMessage.length > 0) {
      setClassName('go-back-to-top');
      setTimeout(() => {
        dispatch(hideResetPinModal());
        dispatch(showResetPinForm());
      }, timeout);
    }
  };
  return (
    <>
      <div className={className}>
        <div className="container">
          <div className="row">
            <div
              id="modal"
              className="text-center text-capitalize modal-info"
            >
              <div className="tck-prev">
                <div className="ticket">
                  <div className="img-set">
                    <img src={logo} alt="mathysbet logo" />
                  </div>
                  {content}
                </div>
                <article className="actions text-center d-flex justify-content-center align-items-center">
                  {loginMessage && loginMessage.length > 0 && (
                  <button
                    type="button"
                    className="btn btnClose"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    <IoMdClose style={iconStyle} />
                  </button>

                  )}
                  {resetPinMessage && resetPinMessage.length > 0 && (
                    <button
                      type="button"
                      className="btn btnClose"
                      onClick={() => {
                        closeModal();
                      }}
                    >
                      <IoMdClose style={iconStyle} />
                    </button>

                  )}
                </article>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginModal;
