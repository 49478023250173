/* eslint jsx-a11y/label-has-associated-control: 0 */
/* eslint  max-len: 0 */
import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import '../../../styles/_dashboard.scss';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import logo from '../../../assets/images/mathys/png/logo.png';
import person from '../../../assets/images/mathys/svg/person.svg';
import arrowDown from '../../../assets/images/mathys/svg/chevron-down.svg';
import arrowLeft from '../../../assets/images/mathys/svg/chevron-left.svg';
import CodeBetForm from './CodeBetForm';
import MenuProfile from './MenuProfile';

const TopBar = ({ isMobile }) => {
  const [displayMessage, setDisplayMessage] = useState(false);
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { agentId } = useSelector((store) => store.login);
  const [alertMessage, setAlertMessage] = useState('');
  const { details, tournament, dashboard } = useSelector((store) => store.navigationHeader);

  const [toggleMenuProfile, setToggleMenuProfile] = useState(false);

  const closeMenuProfile = () => {
    setToggleMenuProfile(false);
  };

  const header = () => {
    if (pathname.includes('paris') || pathname.includes('matches')) {
      return tournament;
    }

    if (pathname.includes('details')) {
      return details;
    }
    return (dashboard && !pathname.includes('home')) ? dashboard : null;
  };

  return (
    <>
      <div className="header">
        <nav className="navbar navbar-expand-md">
          <a className="navbar-brand" href="/">
            <img src={logo} alt="logo" />
          </a>
          {isMobile ? (
            <button
              className="navbar-toggler"
              type="button"
              onClick={() => {
                setToggleMenuProfile(true);
              }}
            >
              <img src={person} className="profile" alt="profile" />
              <div className="agent-name">
                <span>Agent</span>
                <span>{agentId}</span>
              </div>
              <img src={arrowDown} className="arrow" alt="tab" />
            </button>
          ) : (
            <CodeBetForm
              setDisplayMessage={setDisplayMessage}
              setAlertMessage={setAlertMessage}
            />
          )}
        </nav>
        {isMobile && header()?.name && (
        <nav className="event-header">
          <NavLink className="event-nav" to={header().redirect}>
            <img src={arrowLeft} className="arrow" alt="tab" />
            <span className="text-uppercase">{t(header().name)}</span>
          </NavLink>
        </nav>
        )}
      </div>
      { isMobile && (<MenuProfile open={toggleMenuProfile} isMobile={isMobile} close={closeMenuProfile} />)}
      {displayMessage && !isMobile && (
      <div className="line comb">
        <div className="div-msg comb-msg">
          <span>Info!</span>
          <p>{alertMessage}</p>
        </div>
      </div>
      )}
    </>
  );
};

TopBar.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default TopBar;
